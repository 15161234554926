import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import axios from 'axios';

function ProtectedRoute({ children }) {
    const [isLoading, setIsLoading] = useState(true);
    const [isValidToken, setIsValidToken] = useState(false);

    useEffect(() => {
        const validateToken = async () => {
            const token = localStorage.getItem('token');

            if (!token) {
                setIsLoading(false);
                setIsValidToken(false);
                return;
            }

            try {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/auth/validate-token`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                if (response.data.valid) {
                    setIsValidToken(true);
                } else {
                    localStorage.removeItem('token');
                    setIsValidToken(false);
                }
            } catch (error) {
                localStorage.removeItem('token');
                setIsValidToken(false);
            } finally {
                setIsLoading(false);
            }
        };

        validateToken();
    }, []);

    if (isLoading) {
        return (
            <div className="flex justify-center items-center min-h-screen">
                <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"></div>
            </div>
        );
    }

    if (!isValidToken) {
        return <Navigate to="/login" />;
    }

    return children;
}

export default ProtectedRoute;
