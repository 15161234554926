import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";

const SensorThresholdManagement = () => {
    const [sensorThresholds, setSensorThresholds] = useState([]);
    const [conductors, setConductors] = useState([]);
    const [formData, setFormData] = useState({
        pond_id: '',
        sensor_id: '',
        sensor_name: '',
        sensor_min: '',
        sensor_max: '',
        min: '',
        max: '',
    });
    const [editing, setEditing] = useState(null);
    const [ponds, setPonds] = useState([]);
    const [sensors, setSensors] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [selectedPondId, setSelectedPondId] = useState('');
    const [loadingConductor, setLoadingConductor] = useState({});

    useEffect(() => {
        fetchPonds();
        fetchSensorThresholds();
    }, []);

    const toggleConductorMode = async (conductorId, currentStatus) => {
        const token = localStorage.getItem('token');
        const action = currentStatus === 'on' ? 'off' : 'on';
        setLoadingConductor((prev) => ({ ...prev, [conductorId]: true }));

        try {
            await axios.post(
                `${process.env.REACT_APP_BASE_URL}/api/conductors/control`,
                { action, conductorId },
                { headers: { Authorization: `Bearer ${token}` } }
            );

            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: `Conductor turned ${action}`,
            });
            // Fetch updated conductors
            fetchConductors(selectedPondId);
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Failed to toggle conductor status',
                text: error.response?.data?.message || 'An error occurred while toggling conductor status.',
            });
        } finally {
            setLoadingConductor((prev) => ({ ...prev, [conductorId]: false }));
        }
    };

    const fetchPonds = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/pond/all`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setPonds(response.data);
        } catch (error) {
            Swal.fire('Error', 'Failed to fetch ponds', 'error');
        }
    };

    const fetchSensors = async (pondId) => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/sensors/pond/${pondId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setSensors(response.data);
        } catch (error) {
            Swal.fire('Error', 'Failed to fetch sensors', 'error');
        }
    };

    const fetchSensorThresholds = async (pondId = '') => {
        const token = localStorage.getItem('token');
        const url = pondId
            ? `${process.env.REACT_APP_BASE_URL}/api/sensor-thresholds/threshold-by-pond/${pondId}`
            : `${process.env.REACT_APP_BASE_URL}/api/sensor-thresholds`;

        try {
            const response = await axios.get(url, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            setSensorThresholds(response.data);
            setLoading(false);
        } catch (error) {
            Swal.fire('Error', 'Failed to fetch sensor thresholds', 'error');
            setLoading(false);
        }
    };

    const fetchConductors = async (pondId) => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/conductors/pond/${pondId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setConductors(response.data);
        } catch (error) {
            Swal.fire('Error', 'Failed to fetch conductors', 'error');
        }
    };

    const handlePondChange = (e) => {
        const pondId = e.target.value;
        setFormData({
            ...formData,
            pond_id: pondId,
            sensor_id: '',
        });
        if(pondId){
            fetchSensors(pondId);
        }

    };

    const handlePondSelectionChange = (e) => {
        const pondId = e.target.value;
        setSelectedPondId(pondId);
        fetchSensorThresholds(pondId);
        if(pondId){
            fetchConductors(pondId);
        }
        else{
            setConductors([]);
        }

    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('token');
        setLoading(true);
        try {
            if (editing) {
                await axios.put(`${process.env.REACT_APP_BASE_URL}/api/sensor-thresholds/${editing._id}`, formData, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                Swal.fire('Success', 'Sensor Threshold updated successfully', 'success');
                setLoading(false);
                setIsEditModalOpen(false);
            } else {
                await axios.post(`${process.env.REACT_APP_BASE_URL}/api/sensor-thresholds`, formData, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                Swal.fire('Success', 'Sensor Threshold created successfully', 'success');
                setLoading(false);
                setIsEditModalOpen(false);
            }
            setFormData({
                pond_id: '',
                sensor_id: '',
                sensor_min: '',
                sensor_max: '',
                min: '',
                max: '',
            });
            setEditing(null);
            fetchSensorThresholds(selectedPondId);
        } catch (error) {
            setLoading(false);
            Swal.fire('Error', 'Failed to save sensor threshold', 'error');
        }
    };

    const handleEdit = (threshold) => {
        setFormData({
            pond_id: threshold.pond_id._id,
            sensor_id: threshold.sensor_id._id,
            sensor_name: threshold.sensor_id.sensor_parameter_id.sensor_name,
            sensor_min: threshold.sensor_min,
            sensor_max: threshold.sensor_max,
            min: threshold.min,
            max: threshold.max,
        });
        setEditing(threshold);
        fetchSensors(threshold.pond_id._id)
            .then((fetchedSensors) => {
                // Update the sensors state using the fetched data
                setSensors([...sensors, {
                    _id: threshold.sensor_id._id,
                    sensor_name: threshold.sensor_id.sensor_parameter_id.sensor_name,
                }]);
            })
            .catch((error) => {
                console.error('Error fetching sensors:', error);
            });
        setIsEditModalOpen(true);
    };

    const handleDelete = async (id) => {
        const token = localStorage.getItem('token');
        try {
            await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/sensor-thresholds/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            Swal.fire('Success', 'Sensor Threshold deleted successfully', 'success');
            fetchSensorThresholds(selectedPondId);
        } catch (error) {
            Swal.fire('Error', 'Failed to delete sensor threshold', 'error');
        }
    };

    const closeModal = () => {
        setIsEditModalOpen(false);
        setEditing(null);
        setFormData({
            pond_id: '',
            sensor_id: '',
            sensor_min: '',
            sensor_max: '',
            min: '',
            max: '',
        });
    };

    return (
        <div className="container mx-auto p-6">
            <h1 className="text-3xl font-bold mb-6">Sensor Threshold Management</h1>

            {/* Form for Adding/Editing Sensor Threshold */}
            <button
                onClick={() => setIsEditModalOpen(true)}
                className="bg-blue-500 text-white px-4 py-2 rounded mb-4 hover:bg-blue-600 transition duration-200"
            >
                Add Threshold
            </button>

            {/* Sensor Thresholds Table */}
            <div className="bg-white shadow-md rounded-lg p-6">
                <h2 className="text-2xl font-semibold mb-4">Existing Sensor Thresholds</h2>
                <div className="mb-6">
                <label className="block text-gray-700">Select Pond to View Thresholds</label>
                    <select
                        value={selectedPondId}
                        onChange={handlePondSelectionChange}
                        className="w-full p-3 border border-gray-300 rounded mt-1"
                    >
                        <option value="">Select a pond</option>
                        {ponds.map((pond) => (
                            <option key={pond._id} value={pond._id}>
                                {pond.name}
                            </option>
                        ))}
                    </select>
                </div>
                {conductors.length > 0 && (
                    <div className="bg-blue-200 shadow-md rounded-lg p-6 mb-6">
                        <h2 className="text-3xl mb-6 font-bold text-center text-cyan-700">Conductors Status</h2>
                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
                            {conductors.map((conductor, index) => (
                                <div key={conductor._id}
                                     className="flex items-center justify-between bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
                                    <h3 className="text-xl font-semibold text-gray-800">Conductor-{index+1}</h3>
                                    <button
                                        onClick={() => toggleConductorMode(conductor._id, conductor.status)}
                                        className={`flex items-center px-4 py-2 rounded-full transition duration-200 font-bold ${
                                            conductor.status === 'on' ? 'bg-blue-500 text-white' : 'bg-orange-300 text-emerald-950'
                                        }`}
                                    >
                                        {loadingConductor[conductor._id] ? (
                                            <FontAwesomeIcon icon={faSpinner} spin />
                                        ) : (
                                            conductor.status === 'on' ? 'Manual' : 'Auto'
                                        )}
                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                {loading ? (
                    <div className="flex items-center justify-center">
                        <div className="flex items-center space-x-2 animate-pulse">
                            <div className="w-4 h-4 bg-blue-500 rounded-full"></div>
                            <div className="w-4 h-4 bg-blue-500 rounded-full"></div>
                            <div className="w-4 h-4 bg-blue-500 rounded-full"></div>
                            <div className="text-blue-500 text-lg font-semibold">Loading...</div>
                        </div>
                    </div>
                ) : (
                    <table className="min-w-full bg-white border">
                        <thead>
                        <tr>
                            <th className="py-2 px-4 border-b">Pond Name</th>
                            <th className="py-2 px-4 border-b">Sensor Name</th>
                            <th className="py-2 px-4 border-b">Sensor Min</th>
                            <th className="py-2 px-4 border-b">Sensor Max</th>
                            <th className="py-2 px-4 border-b">Min</th>
                            <th className="py-2 px-4 border-b">Max</th>
                            <th className="py-2 px-4 border-b">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {sensorThresholds.map((threshold) => (
                            <tr key={threshold._id}>
                                <td className="py-2 px-4 border-b text-center">{threshold.pond_id.name}</td>
                                <td className="py-2 px-4 border-b text-center">{threshold.sensor_id.sensor_parameter_id.sensor_name}</td>
                                <td className="py-2 px-4 border-b text-center">{threshold.sensor_min}</td>
                                <td className="py-2 px-4 border-b text-center">{threshold.sensor_max}</td>
                                <td className="py-2 px-4 border-b text-center">{threshold.min}</td>
                                <td className="py-2 px-4 border-b text-center">{threshold.max}</td>
                                <td className="py-2 px-4 border-b text-center">
                                    <button
                                        onClick={() => handleEdit(threshold)}
                                        className="bg-yellow-500 text-white px-4 py-2 rounded mr-2 hover:bg-yellow-600"
                                    >
                                        Edit
                                    </button>
                                    <button
                                        onClick={() => handleDelete(threshold._id)}
                                        className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
                                    >
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                )}
            </div>

            {/* Edit Modal */}
            {isEditModalOpen && (
                <div className="fixed z-50 inset-0 overflow-y-auto">
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                        </div>
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                            &#8203;
                        </span>
                        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                            {loading ? (
                                    <div className="flex items-center p-10">
                                        <div className="flex items-center space-x-2 animate-pulse">
                                            <div className="w-4 h-4 bg-blue-500 rounded-full"></div>
                                            <div className="w-4 h-4 bg-blue-500 rounded-full"></div>
                                            <div className="w-4 h-4 bg-blue-500 rounded-full"></div>
                                            <div className="text-blue-500 text-lg font-semibold">Loading...</div>
                                        </div>
                                    </div>
                                ):
                                (<div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                    <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4">
                                        {editing ? 'Edit Sensor Threshold' : 'Add Sensor Threshold'}
                                    </h3>
                                    <form onSubmit={handleSubmit} className="space-y-4">
                                        <div>
                                            <label className="block text-gray-700">Select Pond</label>
                                            <select
                                                name="pond_id"
                                                value={formData.pond_id}
                                                onChange={handlePondChange}
                                                className="w-full p-3 border border-gray-300 rounded mt-1"
                                                required
                                            >
                                                <option value="">Select a pond</option>
                                                {ponds.map((pond) => (
                                                    <option key={pond._id} value={pond._id}>
                                                        {pond.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div>
                                            <label className="block text-gray-700">Select Sensor</label>
                                            <select
                                                name="sensor_id"
                                                value={formData.sensor_id}
                                                onChange={handleChange}
                                                className="w-full p-3 border border-gray-300 rounded mt-1"
                                                required
                                                disabled={!formData.pond_id}
                                            >
                                                <option value=''>Select a sensor</option>
                                                {sensors.map((sensor) => (
                                                    <option key={sensor._id} value={sensor._id}>
                                                        {sensor.sensor_name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="flex space-x-4">
                                            <div className="w-1/2">
                                                <label className="block text-gray-700">Sensor Min</label>
                                                <input
                                                    type="number"
                                                    name="sensor_min"
                                                    value={formData.sensor_min}
                                                    onChange={handleChange}
                                                    className="w-full p-3 border border-gray-300 rounded mt-1"
                                                    required
                                                />
                                            </div>
                                            <div className="w-1/2">
                                                <label className="block text-gray-700">Sensor Max</label>
                                                <input
                                                    type="number"
                                                    name="sensor_max"
                                                    value={formData.sensor_max}
                                                    onChange={handleChange}
                                                    className="w-full p-3 border border-gray-300 rounded mt-1"
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="flex space-x-4">
                                            <div className="w-1/2">
                                                <label className="block text-gray-700">Min</label>
                                                <input
                                                    type="number"
                                                    name="min"
                                                    value={formData.min}
                                                    onChange={handleChange}
                                                    className="w-full p-3 border border-gray-300 rounded mt-1"
                                                    required
                                                />
                                            </div>
                                            <div className="w-1/2">
                                                <label className="block text-gray-700">Max</label>
                                                <input
                                                    type="number"
                                                    name="max"
                                                    value={formData.max}
                                                    onChange={handleChange}
                                                    className="w-full p-3 border border-gray-300 rounded mt-1"
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="flex justify-end">
                                            <button
                                                type="button"
                                                onClick={closeModal}
                                                className="bg-gray-500 text-white p-3 rounded mr-4 hover:bg-gray-600 transition duration-200"
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                type="submit"
                                                className="bg-blue-500 text-white p-3 rounded hover:bg-blue-600 transition duration-200"
                                            >
                                                {editing ? 'Update Threshold' : 'Add Threshold'}
                                            </button>
                                        </div>
                                    </form>
                            </div>)}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SensorThresholdManagement;
