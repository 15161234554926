import React, {useState, useEffect, useCallback} from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import '@sweetalert2/theme-dark/dark.min.css';

function FarmManagement() {
    const [farms, setFarms] = useState([]);
    const [formData, setFormData] = useState({
        name: '',
        location: '',
        area_size: '',
        production_capacity: '',
    });
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(false);
    const [editing, setEditing] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pagination, setPagination] = useState({
        total: 0,
        currentPage: 1,
        totalPages: 1,
    });
    const [pageSize] = useState(6); // Number of items per page



    const fetchFarms = useCallback(async (page = 1,searchTerm) => {
        const token = localStorage.getItem('token');
        try {
            setLoading(true);
            let apiUrl = `${process.env.REACT_APP_BASE_URL}/api/farm/all?page=${page}&limit=${pageSize}`;
            if(searchTerm){
                apiUrl = `${process.env.REACT_APP_BASE_URL}/api/farm/all?page=1&limit=${pageSize}&search=${searchTerm}`;
            }
            const response = await axios.get(apiUrl, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setFarms(response.data.farms);
            setPagination({
                total: response.data.totalFarms,
                currentPage: response.data.currentPage,
                totalPages: response.data.totalPages,
            });
            setCurrentPage(response.data.currentPage);
            setLoading(false);
        } catch (error) {
            Swal.fire('Error', 'Failed to fetch farms.', 'error');
            setLoading(false);
        }
    }, [pageSize]);

    useEffect(() => {
        fetchFarms(currentPage,searchTerm);
    }, [currentPage,fetchFarms, searchTerm]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handlePageChange = (pageNumber) => {
        if (pageNumber > 0 && pageNumber <= pagination.totalPages) {
            setCurrentPage(pageNumber);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('token');
        try {
            if (editing) {
                await axios.put(`${process.env.REACT_APP_BASE_URL}/api/farm/${editing._id}`, formData, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                Swal.fire('Success', 'Farm updated successfully', 'success');
            } else {
                await axios.post(`${process.env.REACT_APP_BASE_URL}/api/farm/create`, formData, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                Swal.fire('Success', 'Farm created successfully', 'success');
            }
            setFormData({
                name: '',
                location: '',
                area_size: '',
                production_capacity: '',
            });
            setEditing(null);
            setIsModalOpen(false);
            fetchFarms(currentPage,searchTerm);
        } catch (error) {
            Swal.fire('Error', 'Failed to save farm.', 'error');
        }
    };

    const handleEdit = (farm) => {
        setFormData({
            name: farm.name,
            location: farm.location,
            area_size: farm.area_size,
            production_capacity: farm.production_capacity,
        });
        setEditing(farm);
        setIsModalOpen(true);
    };

    // const handleDelete = async (id) => {
    //     const token = localStorage.getItem('token');
    //     try {
    //         const result = await Swal.fire({
    //             title: 'Are you sure?',
    //             text: 'Do you want to delete this farm?',
    //             icon: 'warning',
    //             showCancelButton: true,
    //             confirmButtonColor: '#d33',
    //             cancelButtonColor: '#3085d6',
    //             confirmButtonText: 'Yes, delete it!',
    //         });
    //
    //         if (result.isConfirmed) {
    //             await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/farm/${id}`, {
    //                 headers: {
    //                     Authorization: `Bearer ${token}`,
    //                 },
    //             });
    //             Swal.fire('Deleted!', 'Farm has been deleted.', 'success');
    //             fetchFarms(currentPage,searchTerm);
    //         }
    //     } catch (error) {
    //         Swal.fire('Error', 'Failed to delete farm.', 'error');
    //     }
    // };

    const openModal = () => {
        setFormData({
            name: '',
            location: '',
            area_size: '',
            production_capacity: '',
        });
        setEditing(null);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };



    const getPaginationButtons = () => {
        const { currentPage, totalPages } = pagination;
        const pageNumbers = [];

        let startPage = Math.max(currentPage - 2, 1);
        let endPage = Math.min(currentPage + 2, totalPages);

        if (startPage > 1) {
            pageNumbers.push(1);
            if (startPage > 2) pageNumbers.push('...');
        }

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(i);
        }

        if (endPage < totalPages - 1) pageNumbers.push('...');
        if (endPage < totalPages) pageNumbers.push(totalPages);

        return pageNumbers;
    };

    return (
        <div className="container mx-auto p-6">
            <h1 className="text-3xl font-bold mb-6">Farm Management</h1>
            <button
                onClick={openModal}
                className="bg-blue-500 text-white p-3 rounded mb-6 hover:bg-blue-600 transition duration-200"
            >
                Add New Farm
            </button>

            <div className="bg-white shadow-md rounded-lg p-6">
                <h2 className="text-2xl font-semibold mb-4">Farms list</h2>
                <div className="flex items-center justify-between mb-4">
                    <input
                        type="text"
                        value={searchTerm}
                        onChange={e => setSearchTerm(e.target.value)}
                        placeholder="Search Farm Name"
                        className="w-1/2 px-4 py-2 border rounded"
                    />
                </div>
                {loading ? (
                    <div>Loading...</div>
                ) : farms.length > 0 ? (
                    <div>

                        <table className="min-w-full bg-white">
                            <thead>
                            <tr>
                                <th className="py-2 px-4 border-b">Name</th>
                                <th className="py-2 px-4 border-b">Location</th>
                                <th className="py-2 px-4 border-b">Area Size</th>
                                <th className="py-2 px-4 border-b">Number of Ponds</th>
                                <th className="py-2 px-4 border-b">Production Capacity</th>
                                <th className="py-2 px-4 border-b">Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                            {farms.map((farm) => (
                                <tr key={farm._id}>
                                    <td className="py-2 px-4 border-b text-center">{farm.name}</td>
                                    <td className="py-2 px-4 border-b text-center">{farm.location}</td>
                                    <td className="py-2 px-4 border-b text-center">{farm.area_size}</td>
                                    <td className="py-2 px-4 border-b text-center">{farm.number_of_pond}</td>
                                    <td className="py-2 px-4 border-b text-center">{farm.production_capacity}</td>
                                    <td className="py-2 px-4 border-b text-center">
                                        <button
                                            onClick={() => handleEdit(farm)}
                                            className="bg-yellow-500 text-white px-4 py-2 rounded mr-2 hover:bg-yellow-600"
                                        >
                                            Edit
                                        </button>
                                        {/*<button*/}
                                        {/*    onClick={() => handleDelete(farm._id)}*/}
                                        {/*    className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"*/}
                                        {/*>*/}
                                        {/*    Delete*/}
                                        {/*</button>*/}
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                        {pagination.total > 0 && (
                            <div className="mt-6 flex justify-center items-center space-x-2">
                                <button
                                    onClick={() => handlePageChange(1)}
                                    className={`px-3 py-1 rounded ${pagination.currentPage === 1 ? 'bg-gray-300' : 'bg-gray-200 text-gray-800'}`}
                                    disabled={pagination.currentPage === 1}
                                >
                                    First
                                </button>
                                <button
                                    onClick={() => handlePageChange(pagination.currentPage - 1)}
                                    className={`px-3 py-1 rounded ${pagination.currentPage === 1 ? 'bg-gray-300' : 'bg-gray-200 text-gray-800'}`}
                                    disabled={pagination.currentPage === 1}
                                >
                                    Previous
                                </button>

                                {getPaginationButtons().map((page, index) =>
                                    page === '...' ? (
                                        <span key={index} className="px-3 py-1">...</span>
                                    ) : (
                                        <button
                                            key={index}
                                            onClick={() => handlePageChange(page)}
                                            className={`px-3 py-1 rounded ${
                                                pagination.currentPage === page ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-800'
                                            }`}
                                        >
                                            {page}
                                        </button>
                                    )
                                )}

                                <button
                                    onClick={() => handlePageChange(pagination.currentPage + 1)}
                                    className={`px-3 py-1 rounded ${pagination.currentPage === pagination.totalPages ? 'bg-gray-300' : 'bg-gray-200 text-gray-800'}`}
                                    disabled={pagination.currentPage === pagination.totalPages}
                                >
                                    Next
                                </button>
                                <button
                                    onClick={() => handlePageChange(pagination.totalPages)}
                                    className={`px-3 py-1 rounded ${pagination.currentPage === pagination.totalPages ? 'bg-gray-300' : 'bg-gray-200 text-gray-800'}`}
                                    disabled={pagination.currentPage === pagination.totalPages}
                                >
                                    Last
                                </button>
                            </div>
                        )}
                    </div>
                ) : (
                    <p>No farms found.</p>
                )}
            </div>

            {isModalOpen && (
                <div className="fixed z-50 inset-0 overflow-y-auto">
                    <div
                        className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                        </div>
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4">
                                    {editing ? 'Edit Farm' : 'Add New Farm'}
                                </h3>
                                <form onSubmit={handleSubmit} className="space-y-4">
                                    <div>
                                        <label className="block text-gray-700">Name</label>
                                        <input
                                            type="text"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleChange}
                                            className="w-full p-3 border border-gray-300 rounded mt-1 focus:outline-none focus:ring-2 focus:ring-blue-400"
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label className="block text-gray-700">Location</label>
                                        <input
                                            type="text"
                                            name="location"
                                            value={formData.location}
                                            onChange={handleChange}
                                            className="w-full p-3 border border-gray-300 rounded mt-1 focus:outline-none focus:ring-2 focus:ring-blue-400"
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label className="block text-gray-700">Area Size (sq ft)</label>
                                        <input
                                            type="number"
                                            name="area_size"
                                            value={formData.area_size}
                                            onChange={handleChange}
                                            className="w-full p-3 border border-gray-300 rounded mt-1 focus:outline-none focus:ring-2 focus:ring-blue-400"
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label className="block text-gray-700">Production Capacity</label>
                                        <input
                                            type="text"
                                            name="production_capacity"
                                            value={formData.production_capacity}
                                            onChange={handleChange}
                                            className="w-full p-3 border border-gray-300 rounded mt-1 focus:outline-none focus:ring-2 focus:ring-blue-400"
                                            required
                                        />
                                    </div>
                                    <div className="flex justify-end">
                                        <button
                                            type="button"
                                            onClick={closeModal}
                                            className="bg-gray-500 text-white p-3 rounded mr-4 hover:bg-gray-600 transition duration-200"
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="submit"
                                            className="bg-blue-500 text-white p-3 rounded hover:bg-blue-600 transition duration-200"
                                        >
                                            {editing ? 'Update Farm' : 'Add Farm'}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default FarmManagement;