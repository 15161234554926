import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import Select from 'react-select';
import {faEdit, faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"; // for dropdowns

function PeripheralManagement() {
    const [companies, setCompanies] = useState([]);
    const [farms, setFarms] = useState([]);
    const [ponds, setPonds] = useState([]);
    const [devices, setDevices] = useState([]);
    const [conductors, setConductors] = useState([]); // New state for conductors
    const [sensors, setSensors] = useState([]);
    const [peripherals, setPeripherals] = useState([]);
    const [formData, setFormData] = useState({
        company_id: '',
        farm_id: '',
        pond_id: '',
        device_id: '',
        conductor_id: '', // Add conductor_id to form data
        name: '',
        tid: '',
        slave_id: '',
        register_address: '',
        ct_register_address: '',
        sensor_id: null,
    });
    const [editing, setEditing] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchCompanies();
        fetchPeripherals();
    }, []);

    const fetchPeripherals = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/peripherals`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setPeripherals(response.data);
        } catch (error) {
            Swal.fire('Error', 'Failed to fetch peripherals.', 'error');
        }
    };

    const fetchCompanies = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/companies`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setCompanies(response.data);
        } catch (error) {
            Swal.fire('Error', 'Failed to fetch companies.', 'error');
        }
    };

    const fetchFarms = async (companyId) => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/farm/company/${companyId}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setFarms(response.data);
        } catch (error) {
            Swal.fire('Error', 'Failed to fetch farms.', 'error');
        }
    };

    const fetchPonds = async (farmId) => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/pond/farm/${farmId}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setPonds(response.data);
        } catch (error) {
            Swal.fire('Error', 'Failed to fetch ponds.', 'error');
        }
    };

    const fetchDevices = async (pondId) => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/devices/pond/${pondId}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setDevices(response.data);
        } catch (error) {
            Swal.fire('Error', 'Failed to fetch devices.', 'error');
        }
    };

    const fetchConductors = async (deviceId) => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/conductors/device/${deviceId}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            const conductorOptions = response.data.map(conductor => ({
                value: conductor._id,
                label: `${conductor.reg_address}`
            }));
            setConductors(conductorOptions);
        } catch (error) {
            Swal.fire('Error', 'Failed to fetch conductors.', 'error');
        }
    };

    const fetchSensors = async (pondId) => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/sensors/by-pond/${pondId}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            const sensorOptions = response.data.map(sensor => ({
                value: sensor._id,
                label: `${sensor.sensor_parameter_id.sensor_name} (${sensor.name})`
            }));
            setSensors(sensorOptions);
        } catch (error) {
            Swal.fire('Error', 'Failed to fetch sensors.', 'error');
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSelectChange = (selectedOption, name) => {
        let updatedFormData = {
            ...formData,
            [name]: selectedOption ? selectedOption.value : '',
        };

        // Clear dependent fields based on the selected dropdown
        if (name === 'company_id') {
            updatedFormData = {
                ...updatedFormData,
                farm_id: '',
                pond_id: '',
                device_id: '',
                conductor_id: '',
                sensor_id: null,
            };
            setFarms([]);
            setPonds([]);
            setDevices([]);
            setConductors([]);
            setSensors([]);
            if (selectedOption.value) {
                fetchFarms(selectedOption.value);
            }
        } else if (name === 'farm_id') {
            updatedFormData = {
                ...updatedFormData,
                pond_id: '',
                device_id: '',
                conductor_id: '',
                sensor_id: null,
            };
            setPonds([]);
            setDevices([]);
            setConductors([]);
            setSensors([]);
            if (selectedOption.value) {
                fetchPonds(selectedOption.value);
            }
        } else if (name === 'pond_id') {
            updatedFormData = {
                ...updatedFormData,
                device_id: '',
                conductor_id: '',
                sensor_id: null,
            };
            setDevices([]);
            setConductors([]);
            setSensors([]);
            if (selectedOption.value) {
                fetchDevices(selectedOption.value);
                fetchSensors(selectedOption.value);
            }
        } else if (name === 'device_id') {
            updatedFormData = {
                ...updatedFormData,
                conductor_id: '',
            };
            setConductors([]);
            if (selectedOption.value) {
                fetchConductors(selectedOption.value);
            }
        }

        setFormData(updatedFormData);
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('token');
        setLoading(true);
        try {
            if (editing) {
                try {
                    const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/api/peripherals/${editing._id}`, formData, {
                        headers: { Authorization: `Bearer ${token}` },
                    });

                    // If response contains a message, show it using Swal
                    if (response && response.message) {
                        Swal.fire('Success', response.message, 'success');
                    } else {
                        Swal.fire('Success', 'Peripheral updated successfully', 'success');
                    }

                } catch (error) {
                    Swal.fire('Error', 'Failed to update peripheral', 'error');
                }
            } else {
                try {
                    const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/peripherals`, formData, {
                        headers: { Authorization: `Bearer ${token}` },
                    });
                    if (response && response.message) {
                        Swal.fire('Success', response.message, 'success');
                    } else {
                        Swal.fire('Success', 'Peripheral created successfully', 'success');
                    }

                } catch (error) {
                    Swal.fire('Error', error.response.data.message, 'error');
                }
            }
            resetForm();
            setIsModalOpen(false);
            fetchPeripherals();
        } catch (error) {
            console.log(error.response.data.message);
            Swal.fire('Error', error.response.data.message, 'error');
        } finally {
            setLoading(false);
        }
    };

    const handleEdit = async (peripheralId) => {
        const token = localStorage.getItem('token');
        try {
            // Fetch the peripheral details
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/peripherals/${peripheralId}`, {
                headers: { Authorization: `Bearer ${token}` },
            });

            const peripheral = response.data;
            const companyId = peripheral.device_id?.pond_id?.farm_id?.company_id?._id || '';
            const farmId = peripheral.device_id?.pond_id?.farm_id?._id || '';
            const pondId = peripheral.device_id?.pond_id?._id || '';
            const deviceId = peripheral.device_id?._id || '';
            const conductorId = peripheral.conductor_id ? peripheral.conductor_id._id : '';
            const sensorId = peripheral.sensor_id ? peripheral.sensor_id._id : null;

            setFormData({
                company_id: companyId,
                farm_id: farmId,
                pond_id: pondId,
                device_id: deviceId,
                conductor_id: conductorId,
                name: peripheral.name,
                tid: peripheral.tid,
                slave_id: peripheral.slave_address,
                register_address: peripheral.reg_address,
                ct_register_address: peripheral.ct_reg_address,
                sensor_id: sensorId,
            });

            // Load dependent data (farms, ponds, devices, conductors, sensors)
            if (companyId) {
                await fetchFarms(companyId);
            }
            if (farmId) {
                await fetchPonds(farmId);
            }
            if (pondId) {
                await fetchDevices(pondId);
                await fetchSensors(pondId);
            }
            if (deviceId) {
                await fetchConductors(deviceId);
            }

            setEditing(peripheral);
            setIsModalOpen(true);
        } catch (error) {
            Swal.fire('Error', 'Failed to fetch peripheral details for editing.', 'error');
        }
    };


    const handleDelete = async (peripheralId) => {
        const token = localStorage.getItem('token');
        try {
            const result = await Swal.fire({
                title: 'Are you sure?',
                text: 'Do you want to delete this peripheral?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Yes, delete it!',
            });

            if (result.isConfirmed) {
                await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/peripherals/${peripheralId}`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                Swal.fire('Deleted!', 'Peripheral has been deleted.', 'success');
                fetchPeripherals();
            }
        } catch (error) {
            Swal.fire('Error', 'Failed to delete peripheral.', 'error');
        }
    };

    // Function to handle On/Off API calls and MQTT publishing
    const handleOnOff = async (peripheralId, action) => {
        const token = localStorage.getItem('token');
        try {
            await axios.post(`${process.env.REACT_APP_BASE_URL}/api/peripherals/control`, {
                action: action, // 'on' or 'off'
                peripheralId: peripheralId
            }, {
                headers: { Authorization: `Bearer ${token}` }
            });
            Swal.fire('Success', `Peripheral turned ${action}`, 'success');
            fetchPeripherals();
        } catch (error) {
            Swal.fire('Error', `Failed to turn ${action} peripheral.`, 'error');
        }
    };

    const openModal = () => {
        resetForm();
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const resetForm = () => {
        setFormData({
            company_id: '',
            farm_id: '',
            pond_id: '',
            device_id: '',
            conductor_id: '', // Reset conductor_id
            name: '',
            tid: '',
            slave_id: '',
            register_address: '',
            ct_register_address: '',
            sensor_id: null,
        });
        setEditing(null);
    };

    const findOption = (id, options) => options.find(option => option.value === id);

    return (
        <div className="container mx-auto p-6">
            <h1 className="text-3xl font-bold mb-6">Peripheral Management</h1>
            <button
                onClick={openModal}
                className="bg-blue-500 text-white p-3 rounded mb-6 hover:bg-blue-600 transition duration-200"
            >
                Add New Peripheral
            </button>

            <div className="bg-white shadow-md rounded-lg p-6">
                <h2 className="text-2xl font-semibold mb-4">Peripherals List</h2>
                {peripherals.length > 0 ? (
                    <table className="min-w-full bg-white">
                        <thead>
                        <tr>
                            <th className="py-2 px-4 border-b">Device ID</th>
                            <th className="py-2 px-4 border-b">Name</th>
                            <th className="py-2 px-4 border-b">TID</th>
                            <th className="py-2 px-4 border-b">Slave ID</th>
                            <th className="py-2 px-4 border-b">Register Address</th>
                            <th className="py-2 px-4 border-b">CT Register Address</th>
                            <th className="py-2 px-4 border-b">Sensor</th>
                            <th className="py-2 px-4 border-b">Status</th>
                            <th className="py-2 px-4 border-b">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {peripherals.map((peripheral) => (
                            <tr key={peripheral._id}>
                                <td className="py-2 px-4 border-b text-center">{peripheral.device_id.serial}</td>
                                <td className="py-2 px-4 border-b text-center">{peripheral.name}</td>
                                <td className="py-2 px-4 border-b text-center">{peripheral.tid}</td>
                                <td className="py-2 px-4 border-b text-center">{peripheral.slave_address}</td>
                                <td className="py-2 px-4 border-b text-center">{peripheral.reg_address}</td>
                                <td className="py-2 px-4 border-b text-center">{peripheral.ct_reg_address}</td>
                                <td className="py-2 px-4 border-b text-center">{peripheral.sensor_id?.name || 'Not Assigned'}</td>
                                <td className="py-2 px-4 border-b text-center">{peripheral.status === 'on' ?
                                    (<span className="bg-emerald-600 text-white font-bold px-2 py-1 rounded">On</span>):(<span className="bg-red-900 text-white px-2 py-1 rounded">Off</span>)}</td>
                                <td className="py-2 px-4 border-b text-center">
                                    <div className="flex flex-col items-center">
                                        {/* Edit and Delete buttons */}
                                        <div className="mb-2 space-x-2">
                                            <button
                                                onClick={() => handleEdit(peripheral._id)}
                                                className="bg-yellow-500 text-white px-2 py-1 rounded hover:bg-yellow-600"
                                            >
                                                <FontAwesomeIcon icon={faEdit}  />
                                            </button>
                                            <button
                                                onClick={() => handleDelete(peripheral._id)}
                                                className="bg-red-500 text-white px-2 py-1 rounded hover:bg-red-600"
                                            >
                                                <FontAwesomeIcon icon={faTrash} />
                                            </button>
                                            {peripheral.status === 'on' ? (
                                                <button
                                                    onClick={() => handleOnOff(peripheral._id, 'off')}
                                                    className="bg-gray-500 text-white font-bold px-2 py-1 rounded hover:bg-gray-600"
                                                >
                                                   Turn Off
                                                </button>
                                            ) : (
                                                <button
                                                    onClick={() => handleOnOff(peripheral._id, 'on')}
                                                    className="bg-green-500 text-white font-bold px-2 py-1 rounded hover:bg-green-600"
                                                >
                                                  Turn On
                                                </button>
                                            )}
                                        </div>

                                        {/* On/Off Toggle button */}

                                    </div>
                                </td>

                            </tr>
                        ))}
                        </tbody>
                    </table>
                ) : (
                    <p>No peripherals found.</p>
                )}
            </div>

            {isModalOpen && (
                <div className="fixed z-50 inset-0 overflow-y-auto">
                    <div
                        className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                        </div>
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen"
                              aria-hidden="true">&#8203;</span>
                        <div
                            className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4">
                                    {editing ? 'Edit Peripheral' : 'Add New Peripheral'}
                                </h3>
                                <form onSubmit={handleSubmit} className="grid grid-cols-2 gap-4">
                                    <div>
                                        <label className="block text-gray-700">Select Company</label>
                                        <Select
                                            options={[
                                                {value: '', label: 'Select Company'},
                                                ...companies.map(company => ({
                                                    value: company._id,
                                                    label: company.name
                                                }))
                                            ]}
                                            onChange={(option) => handleSelectChange(option, 'company_id')}
                                            value={findOption(formData.company_id, companies.map(company => ({
                                                value: company._id,
                                                label: company.name
                                            })))}
                                            className="basic-select"
                                            classNamePrefix="select"
                                        />
                                    </div>
                                    <div>
                                        <label className="block text-gray-700">Select Farm</label>
                                        <Select
                                            options={[{value: '', label: 'Select Farm'},
                                                ...farms.map(farm => ({value: farm._id, label: farm.name}))]
                                                }
                                            onChange={(option) => handleSelectChange(option, 'farm_id')}
                                            value={findOption(formData.farm_id, farms.map(farm => ({
                                                value: farm._id,
                                                label: farm.name
                                            })))??{value: '', label: 'Select Farm'}}
                                            isDisabled={!formData.company_id}
                                            className="basic-select"
                                            classNamePrefix="select"
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label className="block text-gray-700">Select Pond</label>
                                        <Select
                                            options={[{value: '', label: 'Select Pond'},...ponds.map(pond => ({value: pond._id, label: pond.name}))]}
                                            onChange={(option) => handleSelectChange(option, 'pond_id')}
                                            value={findOption(formData.pond_id, ponds.map(pond => ({
                                                value: pond._id,
                                                label: pond.name
                                            })))??{value: '', label: 'Select Pond'}}
                                            isDisabled={!formData.farm_id}
                                            className="basic-select"
                                            classNamePrefix="select"
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label className="block text-gray-700">Select Device</label>
                                        <Select
                                            options={[{value: '', label: 'Select Device'},...devices.map(device => ({value: device._id, label: device.name}))]}
                                            onChange={(option) => handleSelectChange(option, 'device_id')}
                                            value={findOption(formData.device_id, devices.map(device => ({
                                                value: device._id,
                                                label: device.name
                                            })))??{value: '', label: 'Select Device'}}
                                            isDisabled={!formData.pond_id}
                                            className="basic-select"
                                            classNamePrefix="select"
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label className="block text-gray-700">Select Conductor</label>
                                        <Select
                                            options={[{value: '', label: 'Select Conductor'},...conductors]}
                                            onChange={(option) => handleSelectChange(option, 'conductor_id')}
                                            value={findOption(formData.conductor_id, conductors)??{value: '', label: 'Select Conductor'}}
                                            isDisabled={!formData.device_id}
                                            className="basic-select"
                                            classNamePrefix="select"
                                            required
                                        />
                                    </div>
                                    <div className="col-span-2">
                                        <label className="block text-gray-700">Sensor</label>
                                        <Select
                                            options={[{value: '', label: 'Select Sensor'},...sensors]}
                                            onChange={(option) => handleSelectChange(option, 'sensor_id')}
                                            value={findOption(formData.sensor_id, sensors)??{value: '', label: 'Select Sensor'}}
                                            className="basic-select"
                                            classNamePrefix="select"
                                            menuPortalTarget={document.body}
                                            menuPosition="fixed"
                                            isDisabled={!formData.pond_id}
                                            styles={{menuPortal: base => ({...base, zIndex: 9999})}}
                                        />
                                    </div>
                                    <div>
                                        <label className="block text-gray-700">Peripheral Name</label>
                                        <input
                                            type="text"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleChange}
                                            className="w-full p-3 border border-gray-300 rounded mt-1"
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label className="block text-gray-700">TID</label>
                                        <input
                                            type="text"
                                            name="tid"
                                            value={formData.tid}
                                            onChange={handleChange}
                                            className="w-full p-3 border border-gray-300 rounded mt-1"
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label className="block text-gray-700">Slave ID</label>
                                        <input
                                            type="text"
                                            name="slave_id"
                                            value={formData.slave_id}
                                            onChange={handleChange}
                                            className="w-full p-3 border border-gray-300 rounded mt-1"
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label className="block text-gray-700">Register Address</label>
                                        <input
                                            type="text"
                                            name="register_address"
                                            value={formData.register_address}
                                            onChange={handleChange}
                                            className="w-full p-3 border border-gray-300 rounded mt-1"
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label className="block text-gray-700">CT Register Address</label>
                                        <input
                                            type="text"
                                            name="ct_register_address"
                                            value={formData.ct_register_address}
                                            onChange={handleChange}
                                            className="w-full p-3 border border-gray-300 rounded mt-1"
                                            required
                                        />
                                    </div>

                                    <div className="col-span-2 flex justify-end">
                                        <button
                                            type="button"
                                            onClick={closeModal}
                                            className="bg-gray-500 text-white p-3 rounded mr-4"
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="submit"
                                            className="bg-blue-500 text-white p-3 rounded"
                                        >
                                            {loading ? 'Saving...' : editing ? 'Update Peripheral' : 'Add Peripheral'}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default PeripheralManagement;
