import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Sidebar from "./Sidebar";
import Header from "./Header";
import { Outlet } from "react-router-dom";
import axios from 'axios';
import Swal from "sweetalert2";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faTractor, faWater, faWifi, faBan, faBolt, faPlug} from '@fortawesome/free-solid-svg-icons';


const Dashboard = (notificationReceivedAt) => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const [showCards, setShowCards] = useState(false);
    const [dashboardData, setDashboardData] = useState(null);
    const location = useLocation();

    useEffect(() => {
        const storedRoles = JSON.parse(localStorage.getItem('userRoles')) || [];
        if (location.pathname === '/' && (storedRoles.includes('admin') || storedRoles.includes('farmer') || storedRoles.includes('ngo_admin'))) {
            fetchDashboardData();
            setShowCards(true);
        }

    }, [location]);
    const fetchDashboardData = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/dashboard`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            if (response.data.success) {
                setDashboardData(response.data.data);
            }
        } catch (error) {
            await Swal.fire('Error', 'Failed to fetch dashboard data.', 'error');
        }
    };

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    return (
        <div className="flex h-screen">
            <Sidebar isSidebarOpen={isSidebarOpen} />
            <div
                className={`flex-1 transition-all duration-300 ease-in-out ${isSidebarOpen ? 'ml-64' : 'ml-0'}`}
            >
                <Header toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} notificationReceivedAt={notificationReceivedAt}/>
                <div className="flex-1 p-6 pt-20 bg-gray-100 h-full flex flex-col overflow-auto">
                    {/* Show cards only if the path is "/" and showCards is true */}
                    {location.pathname === '/' && showCards && dashboardData && (
                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 w-full p-4">
                            <div
                                className="bg-gradient-to-r from-blue-500 to-blue-700 shadow-lg rounded-lg p-6 flex flex-col items-center justify-center hover:scale-105 transition-transform duration-300">
                                <div className="text-white text-4xl mb-2">
                                    <FontAwesomeIcon icon={faTractor}/>
                                </div>
                                <div className="text-center">
                                    <h2 className="text-lg font-semibold text-white">Total Farms</h2>
                                    <p className="text-2xl font-bold text-white">{dashboardData.farm_count}</p>
                                </div>
                            </div>

                            <div
                                className="bg-gradient-to-r from-green-500 to-green-700 shadow-lg rounded-lg p-6 flex flex-col items-center justify-center hover:scale-105 transition-transform duration-300">
                                <div className="text-white text-4xl mb-2">
                                    <FontAwesomeIcon icon={faWater}/>
                                </div>
                                <div className="text-center">
                                    <h2 className="text-lg font-semibold text-white">Total Ponds</h2>
                                    <p className="text-2xl font-bold text-white">{dashboardData.pond_count}</p>
                                </div>
                            </div>

                            <div
                                className="bg-gradient-to-r from-teal-500 to-teal-700 shadow-lg rounded-lg p-6 flex flex-col items-center justify-center hover:scale-105 transition-transform duration-300">
                                <div className="text-white text-4xl mb-2">
                                    <FontAwesomeIcon icon={faWifi}/>
                                </div>
                                <div className="text-center">
                                    <h2 className="text-lg font-semibold text-white">Ponds Online</h2>
                                    <p className="text-2xl font-bold text-white">{dashboardData.pond_online}</p>
                                </div>
                            </div>

                            <div
                                className="bg-gradient-to-r from-red-500 to-red-700 shadow-lg rounded-lg p-6 flex flex-col items-center justify-center hover:scale-105 transition-transform duration-300">
                                <div className="text-white text-4xl mb-2">
                                    <FontAwesomeIcon icon={faBan}/>
                                </div>
                                <div className="text-center">
                                    <h2 className="text-lg font-semibold text-white">Ponds Offline</h2>
                                    <p className="text-2xl font-bold text-white">{dashboardData.pond_offline}</p>
                                </div>
                            </div>

                            <div
                                className="bg-gradient-to-r from-purple-500 to-purple-700 shadow-lg rounded-lg p-6 flex flex-col items-center justify-center hover:scale-105 transition-transform duration-300">
                                <div className="text-white text-4xl mb-2">
                                    <FontAwesomeIcon icon={faPlug}/>
                                </div>
                                <div className="text-center">
                                    <h2 className="text-lg font-semibold text-white">Peripherals On</h2>
                                    <p className="text-2xl font-bold text-white">{dashboardData.peripherals.on}</p>
                                </div>
                            </div>

                            <div
                                className="bg-gradient-to-r from-purple-500 to-purple-700 shadow-lg rounded-lg p-6 flex flex-col items-center justify-center hover:scale-105 transition-transform duration-300">
                                <div className="text-white text-4xl mb-2">
                                    <FontAwesomeIcon icon={faPlug}/>
                                </div>
                                <div className="text-center">
                                    <h2 className="text-lg font-semibold text-white">Peripherals Off</h2>
                                    <p className="text-2xl font-bold text-white">{dashboardData.peripherals.off}</p>
                                </div>
                            </div>

                            <div
                                className="bg-gradient-to-r from-yellow-500 to-yellow-700 shadow-lg rounded-lg p-6 flex flex-col items-center justify-center hover:scale-105 transition-transform duration-300">
                                <div className="text-white text-4xl mb-2">
                                    <FontAwesomeIcon icon={faBolt}/>
                                </div>
                                <div className="text-center">
                                    <h2 className="text-lg font-semibold text-white">Conductors On</h2>
                                    <p className="text-2xl font-bold text-white">{dashboardData.conductors.on}</p>
                                </div>
                            </div>

                            <div
                                className="bg-gradient-to-r from-yellow-500 to-yellow-700 shadow-lg rounded-lg p-6 flex flex-col items-center justify-center hover:scale-105 transition-transform duration-300">
                                <div className="text-white text-4xl mb-2">
                                    <FontAwesomeIcon icon={faBolt}/>
                                </div>
                                <div className="text-center">
                                    <h2 className="text-lg font-semibold text-white">Conductors Off</h2>
                                    <p className="text-2xl font-bold text-white">{dashboardData.conductors.off}</p>
                                </div>
                            </div>
                        </div>
                    )}
                    {/* Conditionally render <Outlet /> only if the path is not "/" */}
                    {location.pathname !== '/' && <Outlet/>}
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
