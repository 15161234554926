import React, { createContext, useState, useEffect } from 'react';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [userRoles, setUserRoles] = useState([]);
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        // Load roles from localStorage on initialization
        const storedRoles = localStorage.getItem('userRoles');
        if (storedRoles) {
            setUserRoles(JSON.parse(storedRoles));
            setIsAuthenticated(true);
        }
    }, []);

    const updateUserRoles = (roles) => {
        setUserRoles(roles);
        setIsAuthenticated(true);
        // Store roles in localStorage
        localStorage.setItem('userRoles', JSON.stringify(roles));
    };

    const removeUserRoles = () => {
        setUserRoles([]);
        setIsAuthenticated(false);
    };

    return (
        <UserContext.Provider value={{ userRoles, isAuthenticated, updateUserRoles,removeUserRoles }}>
            {children}
        </UserContext.Provider>
    );
};
