// src/firebase.js

import { initializeApp } from 'firebase/app';
import { getMessaging } from 'firebase/messaging';

// Your Firebase configuration (replace with your actual Firebase config)
const firebaseConfig = {
    apiKey: "AIzaSyASw_QJcQb9TtQIv3xvUFSDoBVhQKpc0SE",
    authDomain: "pksf-68e25.firebaseapp.com",
    projectId: "pksf-68e25",
    storageBucket: "pksf-68e25.appspot.com",
    messagingSenderId: "892401988126",
    appId: "1:892401988126:web:08fec34cd42d2e9bceb40f",
    measurementId: "G-W7E66418S1"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

let messaging;

if ('serviceWorker' in navigator && 'PushManager' in window) {
    messaging = getMessaging(app);
} else {
    console.warn("Firebase Messaging is not supported in this browser.");
}

export { messaging };
