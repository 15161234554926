import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';

const DevicePondAssignment = () => {
    const [companies, setCompanies] = useState([]);
    const [farms, setFarms] = useState([]);
    const [ponds, setPonds] = useState([]);
    const [devices, setDevices] = useState([]); // To hold the list of assigned devices
    const [reloadTable, setReloadTable] = useState(false); // State to trigger table reload

    const [selectedCompany, setSelectedCompany] = useState('');
    const [selectedFarm, setSelectedFarm] = useState('');
    const [selectedPond, setSelectedPond] = useState('');
    const [deviceName, setDeviceName] = useState('');
    const [deviceSerial, setDeviceSerial] = useState('');

    useEffect(() => {
        const token = localStorage.getItem('token');
        axios.get(`${process.env.REACT_APP_BASE_URL}/api/companies/`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            setCompanies(response.data);
        }).catch((error) => {
            Swal.fire({
                icon: 'error',
                title: 'Failed to fetch companies',
                text: error.response?.data?.message || 'An error occurred while fetching companies.',
            });
        });
    }, []);

    const fetchFarms = useCallback(() => {
        const token = localStorage.getItem('token');
        axios.get(`${process.env.REACT_APP_BASE_URL}/api/farm/company/${selectedCompany}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            setFarms(response.data);
            setPonds([]); // Clear ponds when company changes
        }).catch((error) => {
            Swal.fire({
                icon: 'error',
                title: 'Failed to fetch farms',
                text: error.response?.data?.message || 'An error occurred while fetching farms.',
            });
        });
    }, [selectedCompany]);

    const fetchPonds = useCallback(() => {
        const token = localStorage.getItem('token');
        axios.get(`${process.env.REACT_APP_BASE_URL}/api/pond/farm/unassigned/${selectedFarm}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            setPonds(response.data);
        }).catch((error) => {
            Swal.fire({
                icon: 'error',
                title: 'Failed to fetch ponds',
                text: error.response?.data?.message || 'An error occurred while fetching ponds.',
            });
        });
    }, [selectedFarm]);

    const fetchDevices = useCallback(async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/devices`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setDevices(response.data);
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Failed to fetch devices',
                text: error.response?.data?.message || 'An error occurred while fetching devices.',
            });
        }
    }, []);

    useEffect(() => {
        if (selectedCompany) {
            fetchFarms();
        }
    }, [selectedCompany, fetchFarms]);

    useEffect(() => {
        if (selectedFarm) {
            fetchPonds();
        }
    }, [selectedFarm, fetchPonds]);

    useEffect(() => {
        fetchDevices();
    }, [reloadTable, fetchDevices]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('token');
        if (!selectedPond || !deviceName || !deviceSerial) {
            Swal.fire({
                icon: 'warning',
                title: 'Validation Error',
                text: 'Please fill out all the fields.',
            });
            return;
        }

        try {
            await axios.post(`${process.env.REACT_APP_BASE_URL}/api/devices`, {
                pond_id: selectedPond,
                name: deviceName,
                serial: deviceSerial
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Device assigned to pond successfully.',
            });

            // Clear the form
            setDeviceName('');
            setDeviceSerial('');
            setSelectedPond('');

            // Trigger the table to reload and fetch ponds again
            setReloadTable(prev => !prev);
            fetchPonds();

        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Failed to assign device to pond',
                text: error.response?.data?.message || 'An error occurred while assigning device to pond.',
            });
        }
    };

    const handleDelete = async (deviceId) => {
        const token = localStorage.getItem('token');
        try {
            const result = await Swal.fire({
                title: 'Are you sure?',
                text: 'Do you want to delete this device?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Yes, delete it!',
            });

            if (result.isConfirmed) {
                await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/devices/${deviceId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                Swal.fire('Deleted!', 'Device has been deleted.', 'success');

                // Trigger the table to reload and fetch ponds again
                setReloadTable(prev => !prev);
                fetchPonds();
            }
        } catch (error) {
            Swal.fire('Error', 'Failed to delete device.', 'error');
        }
    };

    return (
        <div className="container mx-auto p-6">
            <h1 className="text-3xl font-bold mb-6">Assign Device to Pond</h1>
            <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                    <label className="block text-gray-700">Select Company</label>
                    <select
                        value={selectedCompany}
                        onChange={(e) => setSelectedCompany(e.target.value)}
                        className="w-full p-3 border border-gray-300 rounded mt-1"
                    >
                        <option value="">Select a company</option>
                        {companies.map((company) => (
                            <option key={company._id} value={company._id}>
                                {company.name}
                            </option>
                        ))}
                    </select>
                </div>
                <div>
                    <label className="block text-gray-700">Select Farm</label>
                    <select
                        value={selectedFarm}
                        onChange={(e) => setSelectedFarm(e.target.value)}
                        className="w-full p-3 border border-gray-300 rounded mt-1"
                        disabled={!selectedCompany}
                    >
                        <option value="">Select a farm</option>
                        {farms.map((farm) => (
                            <option key={farm._id} value={farm._id}>
                                {farm.name}
                            </option>
                        ))}
                    </select>
                </div>
                <div>
                    <label className="block text-gray-700">Select Pond</label>
                    <select
                        value={selectedPond}
                        onChange={(e) => setSelectedPond(e.target.value)}
                        className="w-full p-3 border border-gray-300 rounded mt-1"
                        disabled={!selectedFarm}
                    >
                        <option value="">Select a pond</option>
                        {ponds.map((pond) => (
                            <option key={pond._id} value={pond._id}>
                                {pond.name}
                            </option>
                        ))}
                    </select>
                </div>
                <div>
                    <label className="block text-gray-700">Device Name</label>
                    <input
                        type="text"
                        value={deviceName}
                        onChange={(e) => setDeviceName(e.target.value)}
                        className="w-full p-3 border border-gray-300 rounded mt-1"
                        placeholder="Enter device name"
                        required
                    />
                </div>
                <div>
                    <label className="block text-gray-700">Device Serial</label>
                    <input
                        type="text"
                        value={deviceSerial}
                        onChange={(e) => setDeviceSerial(e.target.value)}
                        className="w-full p-3 border border-gray-300 rounded mt-1"
                        placeholder="Enter device serial"
                        required
                    />
                </div>
                <button
                    type="submit"
                    className="bg-blue-500 text-white p-3 rounded hover:bg-blue-600 transition duration-200"
                >
                    Assign Device
                </button>
            </form>

            {/* Device List Table */}
            <div className="mt-10">
                <h2 className="text-2xl font-semibold mb-4">Assigned Devices</h2>
                {devices.length > 0 ? (
                    <table className="min-w-full bg-white border">
                        <thead>
                        <tr>
                            <th className="px-4 py-2 border">Device Name</th>
                            <th className="px-4 py-2 border">Device Serial</th>
                            <th className="px-4 py-2 border">Pond</th>
                            <th className="px-4 py-2 border">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {devices.map((device) => (
                            <tr key={device._id}>
                                <td className="px-4 py-2 border text-center">{device.name}</td>
                                <td className="px-4 py-2 border text-center">{device.serial}</td>
                                <td className="px-4 py-2 border text-center">{device.pond_id.name}</td>
                                <td className="px-4 py-2 border text-center">
                                    <button
                                        onClick={() => handleDelete(device._id)}
                                        className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
                                    >
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                ) : (
                    <p>No devices found.</p>
                )}
            </div>
        </div>
    );
};

export default DevicePondAssignment;
