import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faWater,
    faThermometerHalf,
    faVial,
    faInfoCircle,
    faBolt,
    faCogs,
    faSpinner
} from '@fortawesome/free-solid-svg-icons';

const PondSensorDataCards = () => {
    const [pondSensorData, setPondSensorData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingPeripherals, setLoadingPeripherals] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('token');
        axios.get(`${process.env.REACT_APP_BASE_URL}/api/pond/all/sensor-data`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => {
                setPondSensorData(response.data);
                setLoading(false);
            })
            .catch((error) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Failed to fetch pond sensor data',
                    text: error.response?.data?.message || 'An error occurred while fetching pond sensor data.',
                });
                setLoading(false);
            });
    }, []);

    const getSensorIcon = (sensorName) => {
        switch (sensorName) {
            case 'DO':
                return faWater;
            case 'Water Temp':
                return faThermometerHalf;
            case 'Ammonia':
            case 'Water PH':
                return faVial;
            default:
                return faVial;
        }
    };

    const handleDetailsClick = (pondId) => {
        navigate(`/pond-status/${pondId}/details`);
    };

    const togglePeripheralStatus = async (pondId, conductorId, peripheralId, currentStatus) => {
        const token = localStorage.getItem('token');
        const action = currentStatus === 'on' ? 'off' : 'on';
        setLoadingPeripherals((prev) => ({ ...prev, [peripheralId]: true }));
        setLoading(true);

        try {
            await axios.post(`${process.env.REACT_APP_BASE_URL}/api/peripherals/control`, {
                action,
                peripheralId,
            }, {
                headers: { Authorization: `Bearer ${token}` },
            });

            setPondSensorData((prevData) => {
                return prevData.map((pond) => {
                    if (pond.pond_id === pondId) {
                        return {
                            ...pond,
                            conductors: pond.conductors.map((conductor) => {
                                if (conductor._id === conductorId) {
                                    return {
                                        ...conductor,
                                        peripherals: conductor.peripherals.map((peripheral) => {
                                            if (peripheral._id === peripheralId) {
                                                return {
                                                    ...peripheral,
                                                    status: action,
                                                };
                                            }
                                            return peripheral;
                                        }),
                                    };
                                }
                                return conductor;
                            }),
                        };
                    }
                    return pond;
                });
            });
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Failed to toggle peripheral status',
                text: error.response?.data?.message || 'An error occurred while toggling peripheral status.',
            });
        } finally {
            setLoadingPeripherals((prev) => ({ ...prev, [peripheralId]: false }));
            setLoading(false);
        }
    };

    const toggleConductorMode = async (conductorId, currentStatus) => {
        const token = localStorage.getItem('token');
        const action = currentStatus === 'on' ? 'off' : 'on';

        try {
            // Set loading state for this conductor (optional)
            setLoadingPeripherals(prev => ({ ...prev, [conductorId]: true }));
            setLoading(true);
            // API call to toggle conductor status
            await axios.post(`${process.env.REACT_APP_BASE_URL}/api/conductors/control`, {
                action, // 'on' or 'off'
                conductorId,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            // Update the conductor status in the state after successful toggle
            setPondSensorData(prevData =>
                prevData.map(pond => ({
                    ...pond,
                    conductors: pond.conductors.map(conductor =>
                        conductor._id === conductorId
                            ? { ...conductor, status: action }
                            : conductor
                    ),
                }))
            );

            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: `Conductor turned ${action}`,
            });
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Failed to toggle conductor status',
                text: error.response?.data?.message || 'An error occurred while toggling conductor status.',
            });
        } finally {
            // Clear loading state for this conductor (optional)
            setLoadingPeripherals(prev => ({ ...prev, [conductorId]: false }));
            setLoading(false);
        }
    };


    if (loading) {
        return <div className="flex items-center justify-center m-10">
            <div className="flex items-center space-x-2 animate-pulse">
                <div className="w-4 h-4 bg-blue-500 rounded-full"></div>
                <div className="w-4 h-4 bg-blue-500 rounded-full"></div>
                <div className="w-4 h-4 bg-blue-500 rounded-full"></div>
                <div className="text-blue-500 text-lg font-semibold">Loading...</div>
            </div>
        </div>;
    }

    return (
        <div className="pt-20 overflow-auto h-screen bg-gray-100">
            <div className="container mx-auto p-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-8">
                {pondSensorData.map((pond) => (
                    <div key={pond.pond_id}
                         className="bg-white shadow-lg rounded-lg overflow-hidden transition-transform transform hover:scale-105 duration-300 flex flex-col h-full">

                    {/* Card Header */}
                        <div className="bg-cyan-600 text-white p-3 flex flex-col items-center">
                            <h2 className="text-2xl font-bold mb-2">{pond.pond_name}</h2>
                        </div>

                        {/* Card Body */}
                        <div className="p-6 flex-1">
                            <div className="flex flex-wrap gap-6 justify-between">

                                {/* Sensor Data Section */}
                                <div className="flex-1 bg-gray-50 p-4 rounded-lg shadow-md">
                                    <h3 className="text-lg font-semibold text-gray-800 mb-4">Water quality data ( {pond.last_updated} )</h3>
                                    <div className="space-y-4">
                                        {pond.sensors.map((sensor, index) => (
                                            <div key={index}
                                                 className="flex items-center justify-between p-3 bg-white rounded-lg shadow-sm">
                                                <div className="flex items-center">
                                                    <FontAwesomeIcon icon={getSensorIcon(sensor.sensor_name)}
                                                                     className="text-blue-500 mr-3"/>
                                                    <h4 className="text-md font-semibold text-gray-800">{sensor.sensor_name}</h4>
                                                </div>
                                                <p className="text-gray-700">
                                                    <span className="font-bold">{sensor.value}</span> {sensor.unit}
                                                </p>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                {/* Conductor Data Section */}
                                <div className="flex-1 bg-gray-50 p-4 rounded-lg shadow-md">
                                    <h3 className="text-lg font-semibold text-gray-800 mb-4">Conductors</h3>
                                    <div className="space-y-4">
                                        {pond.conductors.length === 0 ? (
                                            <p className="text-gray-500">No conductors available</p>
                                        ) : (
                                            pond.conductors.map((conductor, index) => (
                                                <div key={index}
                                                     className="flex flex-col p-3 bg-white rounded-lg shadow-sm">
                                                    <div className="flex items-center justify-between">
                                                        <div className="flex items-center">
                                                            <FontAwesomeIcon icon={faBolt}
                                                                             className="text-yellow-500 mr-3"/>
                                                            <h4 className="text-md font-semibold text-gray-800">Conductor
                                                                : {index + 1}</h4>
                                                        </div>
                                                        <button
                                                            onClick={() => toggleConductorMode(conductor._id, conductor.status)}
                                                            className={`flex items-center px-4 py-2 rounded-full transition duration-200 font-bold ${
                                                                conductor.status === 'on' ? 'bg-blue-500 text-white' : 'bg-orange-300 text-emerald-950'
                                                            }`}
                                                        >
                                                            {loadingPeripherals[conductor._id] ? (
                                                                <div className="loader rounded-full text-center">
                                                                    <FontAwesomeIcon icon={faSpinner}
                                                                                     className="text-white"/>
                                                                </div> // Show loader when loading
                                                            ) : (
                                                                conductor.status === 'on' ? 'Manual' : 'Auto' // Show the appropriate status
                                                            )}
                                                        </button>

                                                    </div>

                                                    {/* Peripheral Data Section */}
                                                    <div className="mt-4">
                                                        <h5 className="text-md font-semibold text-gray-700 mb-2">Peripherals</h5>
                                                        {conductor.peripherals.length === 0 ? (
                                                            <p className="text-gray-500">No peripherals available</p>
                                                        ) : (
                                                            conductor.peripherals.map((peripheral, pIndex) => (
                                                                <div key={pIndex}
                                                                     className="flex items-center justify-between p-3 bg-gray-100 rounded-lg shadow-sm mt-2">
                                                                    <div className="flex items-center">
                                                                        <FontAwesomeIcon icon={faCogs} className="text-green-500 mr-3"/>
                                                                        <h4 className="text-sm font-medium text-gray-800">{peripheral.name}</h4>
                                                                    </div>
                                                                    <button
                                                                        onClick={() => togglePeripheralStatus(pond.pond_id, conductor._id, peripheral._id, peripheral.status)}
                                                                        className={`relative w-16 h-8 rounded-full transition-colors duration-300 ${
                                                                            peripheral.status === 'on' && conductor.status === 'on' ? 'bg-green-500' : 'bg-gray-300'
                                                                        } flex items-center justify-between gap-2`}
                                                                        disabled={conductor.status !== 'on'}
                                                                    >
                                                                        <span className={`absolute left-1 text-white transition-opacity duration-300 ${
                                                                            peripheral.status === 'on' ? 'opacity-100' : 'opacity-0'
                                                                        }`}>
                                                                            ON
                                                                        </span>
                                                                        <span className={`absolute right-1 text-white transition-opacity duration-300 ${
                                                                            peripheral.status === 'off' ? 'opacity-100' : 'opacity-0'
                                                                        }`}>
                                                                            OFF
                                                                        </span>
                                                                        <div
                                                                            className={`w-6 h-6 m-1 bg-white rounded-full shadow-md transform transition-transform duration-300 ${
                                                                                peripheral.status === 'on' ? 'translate-x-8' : 'translate-x-0'
                                                                            }`}
                                                                        />
                                                                    </button>
                                                                </div>
                                                            ))
                                                        )}
                                                    </div>
                                                </div>
                                            ))
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Card Footer */}
                        <div className="bg-cyan-700 p-4 text-center mt-auto">
                            <button
                                onClick={() => handleDetailsClick(pond.pond_id)}
                                className="bg-white text-blue-500 px-4 py-2 rounded-full hover:bg-blue-100 transition duration-200"
                            >
                                <FontAwesomeIcon icon={faInfoCircle}/> View Details
                            </button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default PondSensorDataCards;
