import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import axios from 'axios';

function RoleProtectedRoute({ children, roles }) {
    const [isLoading, setIsLoading] = useState(true);
    const [hasRole, setHasRole] = useState(false);

    useEffect(() => {
        const checkRoles = async () => {
            const token = localStorage.getItem('token');

            if (!token) {
                setIsLoading(false);
                setHasRole(false);
                return;
            }

            try {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/auth/validate-token`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                if (response.data.valid && roles.some(role => response.data.data.roles.includes(role))) {
                    setHasRole(true);
                } else {
                    setHasRole(false);
                }
            } catch (error) {
                setHasRole(false);
            } finally {
                setIsLoading(false);
            }
        };

        checkRoles();
    }, [roles]);

    if (isLoading) {
        return (
            <div className="flex justify-center items-center min-h-screen">
                <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"></div>
            </div>
        );
    }

    if (!hasRole) {
        return <Navigate to="/not-authorized" />; // Redirect to a Not Authorized page
    }

    return children;
}

export default RoleProtectedRoute;
