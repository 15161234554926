import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select from "react-select";

const PondSensorDataPage = () => {
    const [ponds, setPonds] = useState([]);
    const [selectedPond, setSelectedPond] = useState('');
    const [sensorData, setSensorData] = useState([]);
    const [sensors, setSensors] = useState([]);
    const [selectedSensors, setSelectedSensors] = useState([]);
    const [loading, setLoading] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [pagination, setPagination] = useState({
        total: 0,
        currentPage: 1,
        totalPages: 1,
    });

    const [pageSize] = useState(30); // You can adjust the number of items per page

    useEffect(() => {
        const token = localStorage.getItem('token');
        axios.get(`${process.env.REACT_APP_BASE_URL}/api/pond/all`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            setPonds(response.data);
        }).catch((error) => {
            Swal.fire({
                icon: 'error',
                title: 'Failed to fetch ponds',
                text: error.response?.data?.message || 'An error occurred while fetching ponds.',
            });
        });
    }, []);

    const fetchSensorData = (pondId,sensorIds=null, page = 1) => {

        const token = localStorage.getItem('token');
        setLoading(true);
        let query = `?page=${page}&limit=${pageSize}`;
        if (startDate) query += `&startDate=${startDate.toISOString()}`;
        if (endDate) query += `&endDate=${endDate.toISOString()}`;
        if (sensorIds) query += `&sensor_ids=${sensorIds}`;

        axios.get(`${process.env.REACT_APP_BASE_URL}/api/pond/${pondId}/sensordata${query}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            setSensorData(response.data.data);
            setPagination({
                total: response.data.pagination.total,
                currentPage: response.data.pagination.currentPage,
                totalPages: response.data.pagination.totalPages,
            });
            setLoading(false);
        }).catch((error) => {
            Swal.fire({
                icon: 'error',
                title: 'Failed to fetch sensor data',
                text: error.response?.data?.message || 'An error occurred while fetching sensor data.',
            });
            setLoading(false);
        });
    };

    const fetchSensors = (pondId) => {
        const token = localStorage.getItem('token');
        axios.get(`${process.env.REACT_APP_BASE_URL}/api/sensors/by-pond/${pondId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            setSensors(response.data);
        }).catch((error) => {
            Swal.fire({
                icon: 'error',
                title: 'Failed to fetch sensors',
                text: error.response?.data?.message || 'An error occurred while fetching sensors.',
            });
        });
    };

    const handlePondChange = (e) => {
        const pondId = e.target.value;
        setSelectedPond(pondId);
        if (pondId) {
            fetchSensors(pondId);
            setSelectedSensors([]);
            fetchSensorData(pondId);
        } else {
            setSensorData([]);
            setSensors([]);
            setSelectedSensors([]);
            setPagination({
                total: 0,
                currentPage: 1,
                totalPages: 1,
            });
        }
    };

    const handleSensorChange = (selectedOptions) => {
        setSelectedSensors(selectedOptions);
        if (selectedPond) {
            const sensorIds = selectedOptions.map(option => option.value); // Get sensor IDs from selected options
            fetchSensorData(selectedPond, sensorIds);
        }
    };


    const handleDateChange = () => {
        if (selectedPond) {
            const sensorIds = selectedSensors.map(option => option.value);
            fetchSensorData(selectedPond,sensorIds);
        }
    };

    const handlePageChange = (pageNumber) => {
        if (pageNumber > 0 && pageNumber <= pagination.totalPages) {
            const sensorIds = selectedSensors.map(option => option.value);
            fetchSensorData(selectedPond,sensorIds, pageNumber);
        }
    };

    const getPaginationButtons = () => {
        const { currentPage, totalPages } = pagination;
        const pageNumbers = [];
        let startPage = Math.max(currentPage - 2, 1);
        let endPage = Math.min(currentPage + 2, totalPages);

        if (startPage > 1) {
            pageNumbers.push(1);
            if (startPage > 2) pageNumbers.push('...');
        }

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(i);
        }

        if (endPage < totalPages - 1) pageNumbers.push('...');
        if (endPage < totalPages) pageNumbers.push(totalPages);

        return pageNumbers;
    };

    const temperatureChartData = {
        labels: sensorData.filter(entry => entry.sensor === 'Water Temp').map(entry => entry.time).reverse(),
        datasets: [
            {
                label: 'Temperature (°C)',
                data: sensorData.filter(entry => entry.sensor === 'Water Temp').map(entry => entry.value).reverse(),
                backgroundColor: 'rgba(255, 99, 132, 0.6)',
                borderColor: 'rgba(255, 99, 132, 1)',
                borderWidth: 4,
            },
        ],
    };

    // Prepare data for the pH Level chart
    const pHChartData = {
        labels: sensorData.filter(entry => entry.sensor === 'Water PH').map(entry => entry.time).reverse(),
        datasets: [
            {
                label: 'pH Level',
                data: sensorData.filter(entry => entry.sensor === 'Water PH').map(entry => entry.value).reverse(),
                backgroundColor: 'rgba(54, 162, 235, 0.6)',
                borderColor: 'rgba(54, 162, 235, 1)',
                borderWidth: 4,
            },
        ],
    };

    // Prepare data for the Dissolved Oxygen chart
    const dissolvedOxygenChartData = {
        labels: sensorData.filter(entry => entry.sensor === 'DO').map(entry => entry.time).reverse(),
        datasets: [
            {
                label: 'Dissolved Oxygen (mg/L)',
                data: sensorData.filter(entry => entry.sensor === 'DO').map(entry => entry.value).reverse(),
                backgroundColor: 'rgba(75, 192, 192, 0.6)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 4,
            },
        ],
    };

    const AMONIAChartData = {
        labels: sensorData.filter(entry => entry.sensor === 'Ammonia').map(entry => entry.time).reverse(),
        datasets: [
            {
                label: 'Ammonia (mg/L)',
                data: sensorData.filter(entry => entry.sensor === 'Ammonia').map(entry => entry.value).reverse(),
                backgroundColor: 'rgba(75, 192, 192, 0.6)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 4,
            },
        ],
    };

    return (
        <div className="container mx-auto p-6">
            <h1 className="text-3xl font-bold mb-6">Pond Sensor Data</h1>
            <div className="mb-4">
                <label className="block text-gray-700">Select Pond</label>
                <select
                    value={selectedPond}
                    onChange={handlePondChange}
                    className="w-full p-3 border border-gray-300 rounded mt-1"
                >
                    <option value="">Select a pond</option>
                    {ponds.map((pond) => (
                        <option key={pond._id} value={pond._id}>
                            {pond.name}
                        </option>
                    ))}
                </select>
            </div>

            {sensors.length > 0 && (
                <div className="mb-4">
                    <label className="block text-gray-700">Select Sensors</label>
                    <Select
                        isMulti
                        options={sensors.map(sensor => ({
                            value: sensor._id,
                            label: sensor?.sensor_parameter_id?.sensor_name??''
                        }))}
                        value={selectedSensors}
                        onChange={handleSensorChange}
                        className="w-full border border-gray-300 rounded mt-1"
                        classNamePrefix="react-select"
                    />
                </div>
            )}

            <div className="mb-6 grid grid-cols-1 md:grid-cols-2 gap-6">
                <div className="flex flex-col">
                    <label className="text-gray-600 font-semibold mb-2">Start Date & Time</label>
                    <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        showTimeSelect
                        dateFormat="Pp"
                        className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
                        placeholderText="Select start date and time"
                    />
                </div>
                <div className="flex flex-col">
                    <label className="text-gray-600 font-semibold mb-2">End Date & Time</label>
                    <DatePicker
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        showTimeSelect
                        dateFormat="Pp"
                        className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
                        placeholderText="Select end date and time"
                    />
                </div>
            </div>

            <button
                onClick={handleDateChange}
                className="bg-blue-500 text-white px-4 py-2 rounded mb-6"
            >
                Filter Data
            </button>

            {sensorData.length > 0 && (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8 items-center justify-center mt-12 p-6 bg-gray-100 rounded-lg shadow-lg">
                    <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 mb-6">
                        <h2 className="text-2xl font-semibold mb-4 text-blue-600">Water Temperature (°C)</h2>
                        <Line
                            data={temperatureChartData}
                            options={{
                                responsive: true,
                                scales: {
                                    y: {
                                        min: 0, // Minimum range for Y-axis
                                        max: 50, // Maximum range for Y-axis
                                        title: {
                                            display: true,
                                            text: 'Temperature (°C)',
                                        },
                                    },
                                    x: {
                                        title: {
                                            display: true,
                                            text: 'Time',
                                        },
                                    },
                                },
                            }}
                        />
                    </div>
                    <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 mb-6">
                        <h2 className="text-2xl font-semibold mb-4 text-green-600">pH Level</h2>
                        <Line
                            data={pHChartData}
                            options={{
                                responsive: true,
                                scales: {
                                    y: {
                                        min: 0,
                                        max: 14,
                                        title: {
                                            display: true,
                                            text: 'pH Level',
                                        },
                                    },
                                    x: {
                                        title: {
                                            display: true,
                                            text: 'Time',
                                        },
                                    },
                                },
                            }}
                        />
                    </div>
                    <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 mb-6">
                        <h2 className="text-2xl font-semibold mb-4 text-purple-600">Dissolved Oxygen (mg/L)</h2>
                        <Line
                            data={dissolvedOxygenChartData}
                            options={{
                                responsive: true,
                                scales: {
                                    y: {
                                        min: 0,
                                        max: 20,
                                        title: {
                                            display: true,
                                            text: 'Dissolved Oxygen (mg/L)',
                                        },
                                    },
                                    x: {
                                        title: {
                                            display: true,
                                            text: 'Time',
                                        },
                                    },
                                },
                            }}
                        />
                    </div>
                    <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 mb-6">
                        <h2 className="text-2xl font-semibold mb-4 text-red-600">Ammonia (mg/L)</h2>
                        <Line
                            data={AMONIAChartData}
                            options={{
                                responsive: true,
                                scales: {
                                    y: {
                                        min: 0,
                                        max: 20,
                                        title: {
                                            display: true,
                                            text: 'Ammonia (mg/L)',
                                        },
                                    },
                                    x: {
                                        title: {
                                            display: true,
                                            text: 'Time',
                                        },
                                    },
                                },
                            }}
                        />
                    </div>
                </div>
            )}


            {loading ? (
                <div className="flex items-center justify-center">
                    <div className="flex items-center space-x-2 animate-pulse">
                        <div className="w-4 h-4 bg-blue-500 rounded-full"></div>
                        <div className="w-4 h-4 bg-blue-500 rounded-full"></div>
                        <div className="w-4 h-4 bg-blue-500 rounded-full"></div>
                        <div className="text-blue-500 text-lg font-semibold">Loading...</div>
                    </div>
                </div>
            ) : (
                <>
                    {/* Pagination controls */}
                    {pagination.total > 0 && (
                        <div className="mt-6 flex justify-center items-center space-x-2">
                            <button
                                onClick={() => handlePageChange(1)}
                                className={`px-3 py-1 rounded ${pagination.currentPage === 1 ? 'bg-gray-300' : 'bg-gray-200 text-gray-800'}`}
                                disabled={pagination.currentPage === 1}
                            >
                                First
                            </button>
                            <button
                                onClick={() => handlePageChange(pagination.currentPage - 1)}
                                className={`px-3 py-1 rounded ${pagination.currentPage === 1 ? 'bg-gray-300' : 'bg-gray-200 text-gray-800'}`}
                                disabled={pagination.currentPage === 1}
                            >
                                Previous
                            </button>
                            {getPaginationButtons().map((page, index) =>
                                page === '...' ? (
                                    <span key={index} className="px-3 py-1">...</span>
                                ) : (
                                    <button
                                        key={index}
                                        onClick={() => handlePageChange(page)}
                                        className={`px-3 py-1 rounded ${
                                            pagination.currentPage === page ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-800'
                                        }`}
                                    >
                                        {page}
                                    </button>
                                )
                            )}
                            <button
                                onClick={() => handlePageChange(pagination.currentPage + 1)}
                                className={`px-3 py-1 rounded ${pagination.currentPage === pagination.totalPages ? 'bg-gray-300' : 'bg-gray-200 text-gray-800'}`}
                                disabled={pagination.currentPage === pagination.totalPages}
                            >
                                Next
                            </button>
                            <button
                                onClick={() => handlePageChange(pagination.totalPages)}
                                className={`px-3 py-1 rounded ${pagination.currentPage === pagination.totalPages ? 'bg-gray-300' : 'bg-gray-200 text-gray-800'}`}
                                disabled={pagination.currentPage === pagination.totalPages}
                            >
                                Last
                            </button>
                        </div>
                    )}
                    {/* Sensor Data Table */}
                    <div className="overflow-x-auto mt-6">
                        {sensorData.length > 0 ? (
                            <table className="min-w-full bg-white border">
                                <thead>
                                <tr>
                                    <th className="px-4 py-2 border">Sensor</th>
                                    <th className="px-4 py-2 border">Value</th>
                                    <th className="px-4 py-2 border">Unit</th>
                                    <th className="px-4 py-2 border">Time</th>
                                </tr>
                                </thead>
                                <tbody>
                                {sensorData.map((sensorEntry, index) => (
                                    <tr key={index}>
                                        <td className="px-4 py-2 border text-center">{sensorEntry.sensor}</td>
                                        <td className="px-4 py-2 border text-center">{sensorEntry.value}</td>
                                        <td className="px-4 py-2 border text-center">{sensorEntry.unit}</td>
                                        <td className="px-4 py-2 border text-center">{sensorEntry.time}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        ) : (
                            <div>No sensor data available for this pond.</div>
                        )}
                    </div>

                </>
            )}
        </div>
    );
};

export default PondSensorDataPage;
