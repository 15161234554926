import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';

const PondSensorTable = ({ reload }) => {
    const [pondSensors, setPondSensors] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = () => {
            const token = localStorage.getItem('token');
            axios.get(`${process.env.REACT_APP_BASE_URL}/api/sensors/pondsensors`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then((response) => {
                setPondSensors(response.data);
                setLoading(false);
            }).catch((error) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Failed to fetch pond-sensor data',
                    text: error.response?.data?.message || 'An error occurred while fetching pond-sensor data.',
                });
                setLoading(false);
            });
        };

        fetchData();
    }, [reload]); // Re-fetch data whenever reload changes

    if (loading) {
        return <div className="flex items-center justify-center">
            <div className="flex items-center space-x-2 animate-pulse">
                <div className="w-4 h-4 bg-blue-500 rounded-full"></div>
                <div className="w-4 h-4 bg-blue-500 rounded-full"></div>
                <div className="w-4 h-4 bg-blue-500 rounded-full"></div>
                <div className="text-blue-500 text-lg font-semibold">Loading...</div>
            </div>
        </div>;
    }

    return (
        <div className="mt-6">
            <h1 className="text-3xl font-bold mb-6">Pond-wise Sensor Table</h1>
            <div className="overflow-x-auto">
                <table className="min-w-full bg-white border">
                    <thead>
                    <tr>
                        <th className="px-4 py-2 border">Pond Name</th>
                        <th className="px-4 py-2 border">Sensor Name</th>
                        <th className="px-4 py-2 border">Sensor Type</th>
                    </tr>
                    </thead>
                    <tbody>
                    {pondSensors.map((pondSensor) => (
                        <tr key={pondSensor._id}>
                            <td className="px-4 py-2 border text-center">{pondSensor.pond_id.name}</td>
                            <td className="px-4 py-2 border text-center">{pondSensor.sensor_id.name}</td>
                            <td className="px-4 py-2 border text-center">{pondSensor.sensor_id.sensor_parameter_id?.sensor_name}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default PondSensorTable;
