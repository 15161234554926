import React from 'react';
import { Link } from 'react-router-dom';

function NotAuthorized() {
    return (
        <div className="flex justify-center items-center min-h-screen bg-gray-100">
            <div className="bg-white p-8 rounded-lg shadow-lg text-center">
                <h1 className="text-3xl font-bold text-red-500 mb-4">403 - Not Authorized</h1>
                <p className="text-gray-700 mb-6">
                    Sorry, you do not have the necessary permissions to view this page.
                </p>
                <Link to="/login" className="inline-block bg-blue-500 text-white px-6 py-2 rounded hover:bg-blue-600 transition">
                    Go to Homepage
                </Link>
            </div>
        </div>
    );
}

export default NotAuthorized;
