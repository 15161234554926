import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import Select from 'react-select'; // for dropdowns

function DeviceIntervalManagement() {
    const [companies, setCompanies] = useState([]);
    const [farms, setFarms] = useState([]);
    const [ponds, setPonds] = useState([]);
    const [devices, setDevices] = useState([]);
    const [deviceIntervals, setDeviceIntervals] = useState([]);
    const [companyFilter, setCompanyFilter] = useState('');
    const [farmFilter, setFarmFilter] = useState('');
    const [formData, setFormData] = useState({
        company_id: '',
        farm_id: '',
        pond_id: '',
        device_id: '',
        tid: '',
        slave_address: '',
        reg_address: '',
        interval:''
    });
    const [editing, setEditing] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchCompanies();
        fetchConductors();
    }, []);

    // Fetch Companies
    const fetchCompanies = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/companies`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setCompanies(response.data);
        } catch (error) {
            Swal.fire('Error', 'Failed to fetch companies.', 'error');
        }
    };

    // Fetch Farms when company is selected
    const fetchFarms = async (companyId) => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/farm/company/${companyId}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setFarms(response.data);
            setPonds([]); // Clear the ponds and devices when a new company is selected
            setDevices([]);
        } catch (error) {
            Swal.fire('Error', 'Failed to fetch farms.', 'error');
        }
    };

    // Fetch Ponds when farm is selected
    const fetchPonds = async (farmId) => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/pond/farm/${farmId}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setPonds(response.data);
            setDevices([]); // Clear devices when a new farm is selected
        } catch (error) {
            Swal.fire('Error', 'Failed to fetch ponds.', 'error');
        }
    };

    // Fetch Devices when pond is selected
    const fetchDevices = async (pondId) => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/devices/pond/${pondId}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setDevices(response.data);
        } catch (error) {
            Swal.fire('Error', 'Failed to fetch devices.', 'error');
        }
    };

    const fetchConductors = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/device-interval`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setDeviceIntervals(response.data);
        } catch (error) {
            Swal.fire('Error', 'Failed to fetch deviceIntervals.', 'error');
        }
    };
    const filterConductors = async () => {
        const token = localStorage.getItem('token');
        console.log(farmFilter);
        try {
            const params = {};
            if (companyFilter) params.company_id = companyFilter;
            if (farmFilter) params.farm_id = farmFilter;

            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/device-interval`, {
                headers: { Authorization: `Bearer ${token}` },
                params: params, // Properly send query parameters here
            });
            setDeviceIntervals(response.data);
        } catch (error) {
            Swal.fire('Error', 'Failed to fetch deviceIntervals.', 'error');
        }
    };

    const fetchConductorById = async (deviceIntervalId) => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/device-interval/${deviceIntervalId}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            return response.data;
        } catch (error) {
            Swal.fire('Error', 'Failed to fetch deviceInterval details.', 'error');
            return null;
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSelectChange = (selectedOption, name) => {
        let updatedFormData = {
            ...formData,
            [name]: selectedOption ? selectedOption.value : '',
        };

        // Trigger cascading dropdowns
        if (name === 'company_id') {
            if(selectedOption.value)
            {
                fetchFarms(selectedOption.value);
            }
            updatedFormData = {
                ...updatedFormData,
                farm_id: '',
                pond_id: '',
                device_id: '',
            };
            setFarms([]); // Clear farms, ponds, and devices when company changes
            setPonds([]);
            setDevices([]);
        } else if (name === 'farm_id') {
            if(selectedOption.value)
            {
                fetchPonds(selectedOption.value);
            }
            updatedFormData = {
                ...updatedFormData,
                pond_id: '',
                device_id: '',
            };
            setPonds([]); // Clear ponds and devices when farm changes
            setDevices([]);
        } else if (name === 'pond_id') {
            if(selectedOption.value)
            {
                fetchDevices(selectedOption.value);
            }
            updatedFormData = {
                ...updatedFormData,
                device_id: '',
            };
            setDevices([]); // Clear devices when pond changes
        }
        setFormData(updatedFormData);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('token');
        setLoading(true);
        try {
            if (editing) {
                await axios.put(`${process.env.REACT_APP_BASE_URL}/api/device-interval/${editing._id}`, formData, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                Swal.fire('Success', 'Conductor updated successfully', 'success');
            } else {
                await axios.post(`${process.env.REACT_APP_BASE_URL}/api/device-interval`, formData, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                Swal.fire('Success', 'Conductor created successfully', 'success');
            }
            resetForm();
            setIsModalOpen(false);
            fetchConductors();
        } catch (error) {
            if(error.response?.status === 404){
                Swal.fire('Error', error.response.data.message, 'error');
                setIsModalOpen(false);
                fetchConductors();
            }else{
                Swal.fire('Error', 'Failed to save deviceInterval.', 'error');
            }

        } finally {
            setLoading(false);
        }
    };

    const handleEdit = async (deviceIntervalId) => {
        const deviceIntervalData = await fetchConductorById(deviceIntervalId);
        if (deviceIntervalData) {
            const companyId = deviceIntervalData.device_id?.pond_id?.farm_id?.company_id?._id || '';
            const farmId = deviceIntervalData.device_id?.pond_id?.farm_id?._id || '';
            const pondId = deviceIntervalData.device_id?.pond_id?._id || '';
            const deviceId = deviceIntervalData.device_id?._id || '';

            setFormData({
                company_id: companyId,
                farm_id: farmId,
                pond_id: pondId,
                device_id: deviceId,
                tid: deviceIntervalData.tid,
                slave_address: deviceIntervalData.slave_address,
                reg_address: deviceIntervalData.reg_address,
                interval: deviceIntervalData.interval,
            });

            // Set cascading dropdowns based on the deviceInterval's data to pre-fill the fields.
            await fetchFarms(companyId);
            await fetchPonds(farmId);
            await fetchDevices(pondId);

            setEditing(deviceIntervalData);
            setIsModalOpen(true);
        }
    };

    const handleFilterSelectChange = (selectedOption, name) => {
        if (name === 'company') {
            setCompanyFilter(selectedOption ? selectedOption.value : '');
            setFarmFilter('');
            if(selectedOption.value){
                fetchFarms(selectedOption.value);
            }

        } else if (name === 'farm') {
            setFarmFilter(selectedOption ? selectedOption.value : '');
        }

    };


    const handleDelete = async (deviceIntervalId) => {
        const token = localStorage.getItem('token');
        try {
            const result = await Swal.fire({
                title: 'Are you sure?',
                text: 'Do you want to delete this deviceInterval?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Yes, delete it!',
            });

            if (result.isConfirmed) {
                await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/device-interval/${deviceIntervalId}`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                Swal.fire('Deleted!', 'Conductor has been deleted.', 'success');
                fetchConductors();
            }
        } catch (error) {
            Swal.fire('Error', 'Failed to delete deviceInterval.', 'error');
        }
    };


    const openModal = () => {
        resetForm();
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const resetForm = () => {
        setFormData({
            company_id: '',
            farm_id: '',
            pond_id: '',
            device_id: '',
            tid: '',
            slave_address: '',
            reg_address: '',
            interval: '',
        });
        setEditing(null);
    };

    const findOption = (id, options) => options.find(option => option.value === id);

    return (
        <div className="container mx-auto p-6">
            <h1 className="text-3xl font-bold mb-6">Device Interval Config</h1>
            <button
                onClick={openModal}
                className="bg-blue-500 text-white p-3 rounded mb-6 hover:bg-blue-600 transition duration-200"
            >
                Add Device Interval
            </button>


            <div className="bg-white shadow-md rounded-lg p-6">
                <h2 className="text-2xl font-semibold mb-4">Device Interval Config List</h2>
                <div className="bg-white shadow-md rounded-lg p-6 mb-6">
                    <h2 className="text-2xl font-semibold mb-4">Filter Intervals Config</h2>
                    <div className="grid grid-cols-2 gap-4 mb-4">
                        <div>
                            <label className="block text-gray-700">Select Company</label>
                            <Select
                                options={[{value: '', label: 'Select Company'}, ...companies.map(company => ({
                                    value: company._id,
                                    label: company.name
                                }))]}
                                onChange={(option) => handleFilterSelectChange(option, 'company')}
                                value={companyFilter ? {
                                    value: companyFilter,
                                    label: companies.find(c => c._id === companyFilter)?.name
                                } : {value: '', label: 'Select Company'}}
                                className="basic-select"
                                classNamePrefix="select"
                                required // Indicate that this field is required
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700">Select Farm</label>
                            <Select
                                options={[{value: '', label: 'Select Farm'}, ...farms.map(farm => ({
                                    value: farm._id,
                                    label: farm.name
                                }))]}
                                onChange={(option) => handleFilterSelectChange(option, 'farm')}
                                value={farmFilter ? {
                                    value: farmFilter,
                                    label: farms.find(f => f._id === farmFilter)?.name
                                } : {value: '', label: 'Select Farm'}}
                                isDisabled={!companyFilter}
                                className="basic-select"
                                classNamePrefix="select"
                                required // Indicate that this field is required
                            />
                        </div>
                    </div>
                    <button
                        onClick={() => {
                            if (!companyFilter || !farmFilter) {
                                Swal.fire('Error', 'Both Company and Farm fields are required.', 'error');
                                return;
                            }
                            filterConductors();
                        }}
                        className="bg-blue-500 text-white p-3 rounded hover:bg-blue-600 transition duration-200"
                    >
                        Apply Filter
                    </button>
                </div>

                {deviceIntervals.length > 0 ? (
                    <table className="min-w-full bg-white">
                        <thead>
                        <tr>
                            <th className="py-2 px-4 border-b">Device ID</th>
                            <th className="py-2 px-4 border-b">TID</th>
                            <th className="py-2 px-4 border-b">Slave Address</th>
                            <th className="py-2 px-4 border-b">Register Address</th>
                            <th className="py-2 px-4 border-b">Interval</th>
                            <th className="py-2 px-4 border-b">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {deviceIntervals.map((deviceInterval) => (
                            <tr key={deviceInterval._id}>
                                <td className="py-2 px-4 border-b text-center">{deviceInterval.device_id.serial}</td>
                                <td className="py-2 px-4 border-b text-center">{deviceInterval.tid}</td>
                                <td className="py-2 px-4 border-b text-center">{deviceInterval.slave_address}</td>
                                <td className="py-2 px-4 border-b text-center">{deviceInterval.reg_address}</td>
                                <td className="py-2 px-4 border-b text-center">{deviceInterval.interval} Minutes</td>

                                <td className="py-2 px-4 border-b text-center">
                                    <button
                                        onClick={() => handleEdit(deviceInterval._id)}
                                        className="bg-yellow-500 text-white px-4 py-2 rounded mr-2 hover:bg-yellow-600"
                                    >
                                        Edit
                                    </button>
                                    <button
                                        onClick={() => handleDelete(deviceInterval._id)}
                                        className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
                                    >
                                        Delete
                                    </button>

                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                ) : (
                    <p>No deviceIntervals found.</p>
                )}
            </div>

            {isModalOpen && (
                <div className="fixed z-50 inset-0 overflow-y-auto">
                    <div
                        className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                        </div>
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen"
                              aria-hidden="true">&#8203;</span>
                        <div
                            className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4">
                                    {editing ? 'Edit Device Interval' : 'Add Device Interval'}
                                </h3>
                                <form onSubmit={handleSubmit} className="grid grid-cols-2 gap-4">
                                    <div>
                                        <label className="block text-gray-700">Select Company</label>
                                        <Select
                                            options={[{
                                                value: '',
                                                label: 'Select Company'
                                            }, ...companies.map(company => ({
                                                value: company._id,
                                                label: company.name
                                            }))]}
                                            onChange={(option) => handleSelectChange(option, 'company_id')}
                                            value={findOption(formData.company_id, companies.map(company => ({
                                                value: company._id,
                                                label: company.name
                                            }))) ?? {value: '', label: 'Select Company'}}
                                            className="basic-select"
                                            classNamePrefix="select"
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label className="block text-gray-700">Select Farm</label>
                                        <Select
                                            options={[{value: '', label: 'Select Farm'},...farms.map(farm => ({value: farm._id, label: farm.name}))]}
                                            onChange={(option) => handleSelectChange(option, 'farm_id')}
                                            value={findOption(formData.farm_id, farms.map(farm => ({
                                                value: farm._id,
                                                label: farm.name
                                            })))??{value: '', label: 'Select Farm'}}
                                            isDisabled={!formData.company_id}
                                            className="basic-select"
                                            classNamePrefix="select"
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label className="block text-gray-700">Select Pond</label>
                                        <Select
                                            options={[{value: '', label: 'Select Pond'},...ponds.map(pond => ({value: pond._id, label: pond.name}))]}
                                            onChange={(option) => handleSelectChange(option, 'pond_id')}
                                            value={findOption(formData.pond_id, ponds.map(pond => ({
                                                value: pond._id,
                                                label: pond.name
                                            })))??{value: '', label: 'Select Pond'}}
                                            isDisabled={!formData.farm_id}
                                            className="basic-select"
                                            classNamePrefix="select"
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label className="block text-gray-700">Select Device</label>
                                        <Select
                                            required
                                            options={[{value: '', label: 'Select Device'},...devices.map(device => ({value: device._id, label: device.serial}))]}
                                            onChange={(option) => handleSelectChange(option, 'device_id')}
                                            value={findOption(formData.device_id, devices.map(device => ({
                                                value: device._id,
                                                label: device.serial
                                            })))??{value: '', label: 'Select Device'}}
                                            isDisabled={!formData.pond_id}
                                            className="basic-select"
                                            classNamePrefix="select"

                                        />
                                    </div>
                                    <div>
                                        <label className="block text-gray-700">TID</label>
                                        <input
                                            type="number"
                                            name="tid"
                                            value={formData.tid}
                                            onChange={handleChange}
                                            className="w-full p-3 border border-gray-300 rounded mt-1"
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label className="block text-gray-700">Slave Address</label>
                                        <input
                                            type="text"
                                            name="slave_address"
                                            value={formData.slave_address}
                                            onChange={handleChange}
                                            className="w-full p-3 border border-gray-300 rounded mt-1"
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label className="block text-gray-700">Register Address</label>
                                        <input
                                            type="text"
                                            name="reg_address"
                                            value={formData.reg_address}
                                            onChange={handleChange}
                                            className="w-full p-3 border border-gray-300 rounded mt-1"
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label className="block text-gray-700">Interval</label>
                                        <input
                                            type="number"
                                            name="interval"
                                            value={formData.interval}
                                            onChange={handleChange}
                                            className="w-full p-3 border border-gray-300 rounded mt-1"
                                            required
                                        />
                                    </div>

                                    <div className="col-span-2 flex justify-end">
                                        <button
                                            type="button"
                                            onClick={closeModal}
                                            className="bg-gray-500 text-white p-3 rounded mr-4"
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="submit"
                                            className="bg-blue-500 text-white p-3 rounded"
                                        >
                                            {loading ? 'Saving...' : editing ? 'Update Conductor' : 'Add Conductor'}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default DeviceIntervalManagement;
