import React, { useState, useEffect,useCallback  } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import '@sweetalert2/theme-dark/dark.min.css';

function CompaniesAndPonds() {
    const [ponds, setPonds] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [farms, setFarms] = useState([]);
    const [selectedFarm, setSelectedFarm] = useState('');
    const [selectedCompany, setSelectedCompany] = useState('');
    const [selectedFormCompany, setSelectedFormCompany] = useState('');
    const [pagination, setPagination] = useState({
        totalPonds: 0,
        currentPage: 1,
        totalPages: 1,
    });
    const [formData, setFormData] = useState({
        name: '',
        pond_shape: '',
        pond_meta: {},
        length: '',
        width: '',
        depth: '',
        circumference: '',
        pond_type: '',
        pond_classification: '',
        pond_image: '',
        pond_size: '', // Automatically calculated
    });
    const [editing, setEditing] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [pageSize] = useState(2); // Number of items per page

    useEffect(() => {
        fetchCompanies(); // Fetch the list of companies
    }, []);

    const fetchPonds = useCallback(async (page = 1) => {
        const token = localStorage.getItem('token');
        try {
            let url = `${process.env.REACT_APP_BASE_URL}/api/pond/all/company?page=${page}&limit=${pageSize}`;
            if (selectedCompany) {
                url += `&company_id=${selectedCompany}`;
            }
            const response = await axios.get(url, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setPonds(response.data.ponds);
            setPagination({
                totalPonds: response.data.totalPonds,
                currentPage: response.data.currentPage,
                totalPages: response.data.totalPages,
            });
        } catch (error) {
            Swal.fire('Error', 'Failed to fetch ponds.', 'error');
        }
    }, [selectedCompany, pageSize]);  // Add dependencies

    const fetchFarms = useCallback(async () => {
        const token = localStorage.getItem('token');
        try {
            if (selectedFormCompany) {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/farm/company/${selectedFormCompany}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setFarms(response.data);
            }
        } catch (error) {
            Swal.fire('Error', 'Failed to fetch farms.', 'error');
        }
    }, [selectedFormCompany]);  // Add dependencies

    useEffect(() => {
        fetchPonds(pagination.currentPage);
    }, [selectedCompany, pagination.currentPage, fetchPonds]);

    useEffect(() => {
        fetchFarms();
    }, [selectedFormCompany, fetchFarms]);

    useEffect(() => {
        const calculatePondSize = () => {
            let size = 0;
            let meta = {};

            if (formData.pond_shape === 'rectangle' && formData.length && formData.width && formData.depth) {
                size = formData.length * formData.width * formData.depth;
                meta = {
                    pond_shape: formData.pond_shape,
                    length: formData.length,
                    width: formData.width,
                    depth: formData.depth,
                };
            } else if (formData.pond_shape === 'rounded' && formData.circumference && formData.depth) {
                const radius = formData.circumference / (2 * Math.PI);
                size = Math.PI * radius * radius * formData.depth;
                meta = {
                    pond_shape: formData.pond_shape,
                    circumference: formData.circumference,
                    depth: formData.depth,
                };
            }

            setFormData((prevData) => ({
                ...prevData,
                pond_meta: meta,
                pond_size: size.toFixed(2), // Round to 2 decimal places
            }));
        };

        calculatePondSize();
    }, [formData.pond_shape, formData.length, formData.depth, formData.width, formData.circumference]);

    const handleFarmChange = (e) => {
        setSelectedFarm(e.target.value);
        setFormData((prevData) => ({
            ...prevData,
            farm_id: e.target.value, // Set the selected farm ID in the formData
        }));
    };

    const fetchCompanies = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/companies`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setCompanies(response.data);
        } catch (error) {
            Swal.fire('Error', 'Failed to fetch companies.', 'error');
        }
    };

    // const fetchPonds = async (page = 1) => {
    //     const token = localStorage.getItem('token');
    //     try {
    //         let url = `${process.env.REACT_APP_BASE_URL}/api/pond/all/company?page=${page}&limit=${pageSize}`;
    //         if (selectedCompany) {
    //             url += `&company_id=${selectedCompany}`;
    //         }
    //         const response = await axios.get(url, {
    //             headers: {
    //                 Authorization: `Bearer ${token}`,
    //             },
    //         });
    //         setPonds(response.data.ponds);
    //         setPagination({
    //             totalPonds: response.data.totalPonds,
    //             currentPage: response.data.currentPage,
    //             totalPages: response.data.totalPages,
    //         });
    //     } catch (error) {
    //         Swal.fire('Error', 'Failed to fetch ponds.', 'error');
    //     }
    // };
    // const fetchFarms = async () => {
    //     const token = localStorage.getItem('token');
    //     try {
    //         if(selectedFormCompany){
    //             const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/farm/company/${selectedFormCompany}`, {
    //                 headers: {
    //                     Authorization: `Bearer ${token}`,
    //                 },
    //             });
    //             setFarms(response.data);
    //         }
    //
    //     } catch (error) {
    //         Swal.fire('Error', 'Failed to fetch farms.', 'error');
    //     }
    // };

    const handleCompanyChange = (e) => {
        setPagination({
            totalPonds: 0,
            currentPage: 1,
            totalPages: 1,
        });
        setSelectedCompany(e.target.value);
    };

    const handleFormCompanyChange = (e) => {
        setSelectedFormCompany(e.target.value);
    };

    const handlePageChange = (pageNumber) => {
        if (pageNumber > 0 && pageNumber <= pagination.totalPages) {
            fetchPonds(pageNumber);
        }
    };

    const getPaginationButtons = () => {
        const { currentPage, totalPages } = pagination;
        const pageNumbers = [];

        let startPage = Math.max(currentPage - 2, 1);
        let endPage = Math.min(currentPage + 2, totalPages);

        if (startPage > 1) {
            pageNumbers.push(1);
            if (startPage > 2) pageNumbers.push('...');
        }

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(i);
        }

        if (endPage < totalPages - 1) pageNumbers.push('...');
        if (endPage < totalPages) pageNumbers.push(totalPages);

        return pageNumbers;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('token');
        try {
            if (editing) {
                await axios.put(`${process.env.REACT_APP_BASE_URL}/api/pond/${editing._id}`, formData, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                Swal.fire('Success', 'Pond updated successfully', 'success');
            } else {
                await axios.post(`${process.env.REACT_APP_BASE_URL}/api/pond/create`, formData, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                Swal.fire('Success', 'Pond created successfully', 'success');
            }
            setFormData({
                name: '',
                pond_shape: '',
                pond_meta: {},
                length: '',
                width: '',
                depth: '',
                circumference: '',
                pond_type: '',
                pond_classification: '',
                pond_image: '',
                pond_size: '',
            });
            setSelectedFormCompany('')
            setSelectedFarm('');
            setEditing(null);
            setIsModalOpen(false);
            fetchPonds();
        } catch (error) {
            Swal.fire('Error', 'Failed to save pond.', 'error');
        }
    };


    const handleEdit = (pond) => {
        setFormData({
            name: pond.name,
            pond_shape: pond.pond_meta?.pond_shape,
            length: pond.pond_meta?.length || 0,
            width: pond.pond_meta?.width || 0,
            depth: pond.pond_meta?.depth || 0,
            circumference: pond.pond_meta?.circumference || 0,
            pond_type: pond.pond_type,
            pond_classification: pond.pond_classification,
            pond_image: pond.pond_image,
            pond_size: pond.pond_size,
        });
        setSelectedFormCompany(pond.farm_id?.company_id?._id??'')
        setSelectedFarm(pond.farm_id?._id??'');
        setEditing(pond);
        setIsModalOpen(true);
    };

    const handleDelete = async (id) => {
        const token = localStorage.getItem('token');
        try {
            const result = await Swal.fire({
                title: 'Are you sure?',
                text: 'Do you want to delete this pond?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Yes, delete it!',
            });

            if (result.isConfirmed) {
                await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/pond/${id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                Swal.fire('Deleted!', 'Pond has been deleted.', 'success');
                fetchPonds();
            }
        } catch (error) {
            Swal.fire('Error', 'Failed to delete pond.', 'error');
        }
    };

    const openModal = () => {
        setFormData({
            name: '',
            pond_shape: '',
            length: '',
            width: '',
            depth: '',
            circumference: '',
            pond_type: '',
            pond_classification: '',
            pond_image: '',
            pond_size: '',
        });
        setEditing(null);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setSelectedFormCompany('')
        setSelectedFarm('');
        setIsModalOpen(false);
    };

    return (
        <div className="container mx-auto p-6">
            <h1 className="text-3xl font-bold mb-6">Pond Management</h1>
            <button
                onClick={openModal}
                className="bg-blue-500 text-white p-3 rounded mb-6 hover:bg-blue-600 transition duration-200"
            >
                Add New Pond
            </button>

            {/* Company Filter Dropdown */}
            <div className="mb-6">
                <label className="block text-gray-700 mb-2">Filter by Company</label>
                <select
                    value={selectedCompany}
                    onChange={handleCompanyChange}
                    className="w-full p-3 border border-gray-300 rounded mt-1"
                >
                    <option value="">All Companies</option>
                    {companies.map((company) => (
                        <option key={company._id} value={company._id}>
                            {company.name}
                        </option>
                    ))}
                </select>
            </div>

            <div className="bg-white shadow-md rounded-lg p-6">
                <h2 className="text-2xl font-semibold mb-4">Ponds List</h2>
                {ponds.length > 0 ? (
                    <table className="min-w-full bg-white">
                        <thead>
                        <tr>
                            <th className="py-2 px-4 border-b">Name</th>
                            <th className="py-2 px-4 border-b">Shape</th>
                            <th className="py-2 px-4 border-b">Size (sq ft)</th>
                            <th className="py-2 px-4 border-b">Type</th>
                            <th className="py-2 px-4 border-b">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {ponds.map((pond) => (
                            <tr key={pond._id}>
                                <td className="py-2 px-4 border-b text-center">{pond.name}</td>
                                <td className="py-2 px-4 border-b text-center">{pond.pond_meta?.pond_shape}</td>
                                <td className="py-2 px-4 border-b text-center">{pond.pond_size}</td>
                                <td className="py-2 px-4 border-b text-center">{pond.pond_type}</td>
                                <td className="py-2 px-4 border-b text-center">
                                    <button
                                        onClick={() => handleEdit(pond)}
                                        className="bg-yellow-500 text-white px-4 py-2 rounded mr-2 hover:bg-yellow-600"
                                    >
                                        Edit
                                    </button>
                                    <button
                                        onClick={() => handleDelete(pond._id)}
                                        className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
                                    >
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                ) : (
                    <p>No ponds found.</p>
                )}
            </div>

            {/* Pagination controls */}
            {pagination.totalPonds > 0 && (
                <div className="mt-6 flex justify-center items-center space-x-2">
                    <button
                        onClick={() => handlePageChange(1)}
                        className={`px-3 py-1 rounded ${pagination.currentPage === 1 ? 'bg-gray-300' : 'bg-gray-200 text-gray-800'}`}
                        disabled={pagination.currentPage === 1}
                    >
                        First
                    </button>
                    <button
                        onClick={() => handlePageChange(pagination.currentPage - 1)}
                        className={`px-3 py-1 rounded ${pagination.currentPage === 1 ? 'bg-gray-300' : 'bg-gray-200 text-gray-800'}`}
                        disabled={pagination.currentPage === 1}
                    >
                        Previous
                    </button>

                    {getPaginationButtons().map((page, index) =>
                        page === '...' ? (
                            <span key={index} className="px-3 py-1">...</span>
                        ) : (
                            <button
                                key={index}
                                onClick={() => handlePageChange(page)}
                                className={`px-3 py-1 rounded ${
                                    pagination.currentPage === page ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-800'
                                }`}
                            >
                                {page}
                            </button>
                        )
                    )}

                    <button
                        onClick={() => handlePageChange(pagination.currentPage + 1)}
                        className={`px-3 py-1 rounded ${pagination.currentPage === pagination.totalPages ? 'bg-gray-300' : 'bg-gray-200 text-gray-800'}`}
                        disabled={pagination.currentPage === pagination.totalPages}
                    >
                        Next
                    </button>
                    <button
                        onClick={() => handlePageChange(pagination.totalPages)}
                        className={`px-3 py-1 rounded ${pagination.currentPage === pagination.totalPages ? 'bg-gray-300' : 'bg-gray-200 text-gray-800'}`}
                        disabled={pagination.currentPage === pagination.totalPages}
                    >
                        Last
                    </button>
                </div>
            )}

            {isModalOpen && (
                <div className="fixed z-50 inset-0 overflow-y-auto">
                    <div
                        className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                        </div>
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen"
                              aria-hidden="true">&#8203;</span>
                        <div
                            className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4">
                                    {editing ? 'Edit Pond' : 'Add New Pond'}
                                </h3>
                                <form onSubmit={handleSubmit} className="space-y-4">
                                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                        <div className="mb-4">
                                            <label className="block text-gray-700 mb-2">Company</label>
                                            <select
                                                value={selectedFormCompany}
                                                onChange={handleFormCompanyChange}
                                                className="w-full p-2 border border-gray-900 rounded"
                                                required
                                            >
                                                <option value={formData.farm_id?.company_id?._id??''}>{formData.farm_id?.company_id?.name??'Select Company'}</option>
                                                {companies.map((company) => (
                                                    <option key={company._id} value={company._id}>
                                                        {company.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>

                                        {/* Farm Selection based on selected company */}
                                        <div className="mb-4">
                                            <label className="block text-gray-700 mb-2">Farm</label>
                                            <select
                                                value={selectedFarm}
                                                onChange={handleFarmChange}
                                                className="w-full p-2 border border-gray-900 rounded"
                                                required
                                            >
                                                <option value=''>Select Farm</option>
                                                {farms.map((farm) => (
                                                    <option key={farm._id} value={farm._id}>
                                                        {farm.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="mb-4">
                                        <label className="block text-gray-700 mb-2">Name</label>
                                            <input
                                                type="text"
                                                name="name"
                                                value={formData.name}
                                                onChange={handleChange}
                                                className="w-full p-2 border border-gray-900 rounded"
                                                required
                                            />
                                        </div>
                                        <div className="mb-4">
                                            <label className="block text-gray-700 mb-2">Pond Shape</label>
                                            <select
                                                name="pond_shape"
                                                value={formData.pond_shape}
                                                onChange={handleChange}
                                                className="w-full p-2 border border-gray-900 rounded"
                                                required
                                            >
                                                <option value="">Select Pond Shape</option>
                                                <option value="rounded">Rounded</option>
                                                <option value="rectangle">Rectangle</option>
                                            </select>
                                        </div>

                                        {formData.pond_shape === 'rectangle' && (
                                            <>
                                                <div className="mb-4">
                                                    <label className="block text-gray-700 mb-2">Length (ft)</label>
                                                    <input
                                                        type="number"
                                                        name="length"
                                                        value={formData.length || ''}
                                                        onChange={handleChange}
                                                        className="w-full p-2 border border-gray-900 rounded"
                                                        required
                                                    />
                                                </div>
                                                <div className="mb-4">
                                                    <label className="block text-gray-700 mb-2">Width (ft)</label>
                                                    <input
                                                        type="number"
                                                        name="width"
                                                        value={formData.width || ''}
                                                        onChange={handleChange}
                                                        className="w-full p-2 border border-gray-900 rounded"
                                                        required
                                                    />
                                                </div>
                                            </>
                                        )}

                                        {formData.pond_shape === 'rounded' && (
                                            <div className="mb-4">
                                                <label className="block text-gray-700 mb-2">Circumference (ft)</label>
                                                <input
                                                    type="number"
                                                    name="circumference"
                                                    value={formData.circumference || ''}
                                                    onChange={handleChange}
                                                    className="w-full p-2 border border-gray-900 rounded"
                                                    required
                                                />
                                            </div>
                                        )}

                                        <div className="mb-4">
                                            <label className="block text-gray-700 mb-2">Depth (ft)</label>
                                            <input
                                                type="number"
                                                name="depth"
                                                value={formData.depth || ''}
                                                onChange={handleChange}
                                                className="w-full p-2 border border-gray-900 rounded"
                                                required
                                            />
                                        </div>

                                        <div className="mb-4">
                                            <label className="block text-gray-700 mb-2">Pond Type</label>
                                            <select
                                                name="pond_type"
                                                value={formData.pond_type}
                                                onChange={handleChange}
                                                className="w-full p-2 border border-gray-900 rounded"
                                                required
                                            >
                                                <option value="">Select Pond Type</option>
                                                <option value="culture">Culture</option>
                                                <option value="nursery">Nursery</option>
                                                <option value="service">Service</option>
                                            </select>
                                        </div>

                                        <div className="mb-4">
                                            <label className="block text-gray-700 mb-2">Pond Classification</label>
                                            <select
                                                name="pond_classification"
                                                value={formData.pond_classification}
                                                onChange={handleChange}
                                                className="w-full p-2 border border-gray-900 rounded"
                                                required
                                            >
                                                <option value="">Select Pond Classification</option>
                                                <option value="ordinary">Ordinary</option>
                                                <option value="contraction">Contraction</option>
                                                <option value="r&d">R&D</option>
                                            </select>
                                        </div>

                                        <div className="mb-4">
                                            <label className="block text-gray-700 mb-2">Pond Image URL</label>
                                            <input
                                                type="text"
                                                name="pond_image"
                                                value={formData.pond_image}
                                                onChange={handleChange}
                                                className="w-full p-2 border border-gray-900 rounded"
                                                required
                                            />
                                        </div>

                                        <div className="mb-4">
                                            <label className="block text-gray-700 mb-2">Pond Size (sq ft)</label>
                                            <input
                                                type="text"
                                                name="pond_size"
                                                value={formData.pond_size}
                                                readOnly
                                                className="w-full p-2 border border-gray-900 rounded bg-gray-100"
                                            />
                                        </div>
                                    </div>

                                    <div className="flex justify-end">
                                        <button
                                            type="button"
                                            onClick={closeModal}
                                            className="bg-gray-500 text-white p-3 rounded mr-4 hover:bg-gray-600 transition duration-200"
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="submit"
                                            className="bg-blue-500 text-white p-3 rounded hover:bg-blue-600 transition duration-200"
                                        >
                                            {editing ? 'Update Pond' : 'Add Pond'}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default CompaniesAndPonds;
