import React from 'react';

const ToggleSwitch = ({ isOn, handleToggle }) => {
    return (
        <div
            onClick={handleToggle}
            className={`relative w-16 h-8 rounded-full transition-colors duration-300 ${
                isOn ? 'bg-green-500' : 'bg-gray-300'
            } flex items-center justify-between gap-2`}
        >
            <span
                className={`absolute left-1 text-sm font-bold text-white ${
                    isOn ? 'opacity-100' : 'opacity-0'
                } transition-opacity duration-300`}
            >
                ON
            </span>
            <span
                className={`absolute right-1 text-sm font-bold text-white ${
                    !isOn ? 'opacity-100' : 'opacity-0'
                } transition-opacity duration-300`}
            >
                OFF
            </span>
            <div
                className={`w-6 h-6 m-1 rounded-full bg-white transform transition-transform duration-300 ${
                    isOn ? 'translate-x-8' : 'translate-x-0'
                }`}
            />
        </div>
    );
};

export default ToggleSwitch;
