import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import Select from 'react-select';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function PeripheralThresholdManagement() {
    const [companies, setCompanies] = useState([]);
    const [farms, setFarms] = useState([]);
    const [ponds, setPonds] = useState([]);
    const [devices, setDevices] = useState([]);
    const [sensors, setSensors] = useState([]);
    const [thresholds, setThresholds] = useState([]);
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        company_id: '',
        farm_id: '',
        pond_id: '',
        device_id: '',
        sensor_id: '',
        tid: '',
        slave_address: '',
        min_reg_address: '',
        max_reg_address: '',
    });
    const [editing, setEditing] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        fetchCompanies();
        fetchThresholds();
    }, []);

    const fetchThresholds = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/peripheral-thresholds`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setThresholds(response.data);
        } catch (error) {
            Swal.fire('Error', 'Failed to fetch thresholds.', 'error');
        }
    };

    const fetchCompanies = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/companies`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setCompanies(response.data);
        } catch (error) {
            Swal.fire('Error', 'Failed to fetch companies.', 'error');
        }
    };

    const fetchFarms = async (companyId) => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/farm/company/${companyId}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setFarms(response.data);
            setPonds([]);  // Clear ponds when company changes
            setDevices([]);
        } catch (error) {
            Swal.fire('Error', 'Failed to fetch farms.', 'error');
        }
    };

    const fetchPonds = async (farmId) => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/pond/farm/${farmId}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setPonds(response.data);
            setDevices([]);
        } catch (error) {
            Swal.fire('Error', 'Failed to fetch ponds.', 'error');
        }
    };

    const fetchSingleThreshold = async (id) => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/peripheral-thresholds/${id}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setFormData({
                company_id: response.data.device_id?.pond_id?.farm_id?.company_id?._id || '',
                farm_id: response.data.device_id?.pond_id?.farm_id?._id || '',
                pond_id: response.data.device_id?.pond_id?._id || '',
                device_id: response.data.device_id?._id || '',
                sensor_id: response.data.sensor_id,
                tid: response.data.tid,
                slave_address: response.data.slave_address,
                min_reg_address: response.data.min_reg_address,
                max_reg_address: response.data.max_reg_address,
            });
            if(response.data.device_id?.pond_id?.farm_id?.company_id?._id){
                const companyId = response.data.device_id?.pond_id?.farm_id?.company_id?._id;
                await fetchFarms(companyId);
            }
            if(response.data.device_id?.pond_id?.farm_id?._id){
                const farmId = response.data.device_id?.pond_id?.farm_id?._id;
                await fetchPonds(farmId);
            }
            if(response.data.device_id?.pond_id?._id){
                const pondId = response.data.device_id?.pond_id?._id;
                await fetchDevices(pondId);
                await fetchSensors(pondId);
            }

        } catch (error) {
            Swal.fire('Error', 'Failed to fetch ponds.', 'error');
        }
    };

    const fetchDevices = async (pondId) => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/devices/pond/${pondId}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setDevices(response.data);
        } catch (error) {
            Swal.fire('Error', 'Failed to fetch devices.', 'error');
        }
    };

    const fetchSensors = async (pondId) => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/sensors/by-pond/${pondId}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            const sensorOptions = response.data.map(sensor => ({
                value: sensor._id,
                label: `${sensor.sensor_parameter_id.sensor_name} (${sensor.name})`
            }));
            setSensors(sensorOptions);
        } catch (error) {
            Swal.fire('Error', 'Failed to fetch sensors.', 'error');
        }
    };

    const handleSelectChange = async (selectedOption, name) => {
        console.log(selectedOption, name);
        let updatedFormData = { ...formData, [name]: selectedOption ? selectedOption.value : '' };

        // Clear related fields when a parent is changed
        switch (name) {
            case 'company_id':
                // Reset related fields when company is selected
                updatedFormData = {
                    ...updatedFormData,
                    farm_id: '',
                    pond_id: '',
                    device_id: '',
                    sensor_id: ''
                };
                setFarms([]);     // Clear farms
                setPonds([]);     // Clear ponds
                setDevices([]);   // Clear devices
                // Clear peripherals
                await fetchFarms(selectedOption ? selectedOption.value : null);
                break;

            case 'farm_id':
                // Reset fields dependent on farm selection
                updatedFormData = {
                    ...updatedFormData,
                    pond_id: '',
                    device_id: '',
                    sensor_id: ''
                };
                setPonds([]);
                setDevices([]);
                await fetchPonds(selectedOption ? selectedOption.value : null);
                break;

            case 'pond_id':
                // Reset fields dependent on pond selection
                updatedFormData = {
                    ...updatedFormData,
                    device_id: '',
                    sensor_id: ''
                };
                setDevices([]);
                setSensors([]);
                await fetchDevices(selectedOption ? selectedOption.value : null);
                await fetchSensors(selectedOption ? selectedOption.value : null);
                break;

            case 'device_id':
                // Reset fields dependent on device selection
                updatedFormData = {
                    ...updatedFormData,
                    sensor_id: ''
                };
                break;

            default:
                break;
        }

        setFormData(updatedFormData);

    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('token');
        setLoading(true);
        try {
            if (editing) {
                await axios.put(`${process.env.REACT_APP_BASE_URL}/api/peripheral-thresholds/${editing._id}`, formData, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                Swal.fire('Success', 'Threshold updated successfully', 'success');
            } else {
                await axios.post(`${process.env.REACT_APP_BASE_URL}/api/peripheral-thresholds`, formData, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                Swal.fire('Success', 'Threshold created successfully', 'success');
            }
            resetForm();
            setIsModalOpen(false);
            fetchThresholds();
        } catch (error) {
            Swal.fire('Error', 'Failed to save threshold.', 'error');
        } finally {
            setLoading(false);
        }
    };

    const handleEdit =async (threshold) => {
        await fetchSingleThreshold(threshold._id);
        setEditing(threshold);
        setIsModalOpen(true);
    };

    const handleDelete = async (thresholdId) => {
        const token = localStorage.getItem('token');
        try {
            const result = await Swal.fire({
                title: 'Are you sure?',
                text: 'Do you want to delete this threshold?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Yes, delete it!',
            });

            if (result.isConfirmed) {
                await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/peripheral-thresholds/${thresholdId}`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                Swal.fire('Deleted!', 'Threshold has been deleted.', 'success');
                fetchThresholds();
            }
        } catch (error) {
            Swal.fire('Error', 'Failed to delete threshold.', 'error');
        }
    };

    const openModal = () => {
        resetForm();
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        resetForm();
        setFarms([]);
        setPonds([]);
        setDevices([]);
        setSensors([]);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const resetForm = () => {
        setFormData({
            company_id: '',
            farm_id: '',
            pond_id: '',
            device_id: '',
            sensor_id: '',
            tid: '',
            slave_address: '',
            min_reg_address: '',
            max_reg_address: '',
        });
        setEditing(null);
    };

    const findOption = (id, options) => options.find((option) => option.value === id) || null;

    return (
        <div className="container mx-auto p-6">
            <h1 className="text-3xl font-bold mb-6">Peripheral Threshold Management</h1>
            <button
                onClick={openModal}
                className="bg-blue-500 text-white p-3 rounded mb-6 hover:bg-blue-600 transition duration-200"
            >
                Add New Threshold
            </button>

            <div className="bg-white shadow-md rounded-lg p-6">
                <h2 className="text-2xl font-semibold mb-4">Threshold List</h2>
                {thresholds.length > 0 ? (
                    <table className="min-w-full bg-white">
                        <thead>
                        <tr>
                            <th className="py-2 px-4 border-b">Device</th>
                            <th className="py-2 px-4 border-b">TID</th>
                            <th className="py-2 px-4 border-b">Slave Address</th>
                            <th className="py-2 px-4 border-b">Min Reg Address</th>
                            <th className="py-2 px-4 border-b">Max Reg Address</th>
                            <th className="py-2 px-4 border-b">Sensor</th>
                            <th className="py-2 px-4 border-b">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {thresholds.map((threshold) => (
                            <tr key={threshold._id}>

                                <td className="py-2 px-4 border-b text-center">
                                    {threshold.device_id.name}
                                </td>
                                <td className="py-2 px-4 border-b text-center">{threshold.tid}</td>
                                <td className="py-2 px-4 border-b text-center">{threshold.slave_address}</td>
                                <td className="py-2 px-4 border-b text-center">{threshold.min_reg_address}</td>
                                <td className="py-2 px-4 border-b text-center">{threshold.max_reg_address}</td>
                                <td className="py-2 px-4 border-b text-center">
                                    {threshold.sensor_id?.name || 'Not Assigned'}
                                </td>
                                <td className="py-2 px-4 border-b text-center">
                                    <div className="flex flex-col items-center">
                                        <div className="mb-2 space-x-2">
                                            <button
                                                onClick={() => handleEdit(threshold)}
                                                className="bg-yellow-500 text-white px-2 py-1 rounded hover:bg-yellow-600"
                                            >
                                                <FontAwesomeIcon icon={faEdit}/>
                                            </button>
                                            <button
                                                onClick={() => handleDelete(threshold._id)}
                                                className="bg-red-500 text-white px-2 py-1 rounded hover:bg-red-600"
                                            >
                                                <FontAwesomeIcon icon={faTrash}/>
                                            </button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                ) : (
                    <p>No thresholds found.</p>
                )}
            </div>

            {isModalOpen && (
                <div className="fixed z-50 inset-0 overflow-y-auto">
                    <div
                        className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                        </div>
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                            &#8203;
                        </span>
                        <div
                            className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4">
                                    {editing ? 'Edit Threshold' : 'Add New Threshold'}
                                </h3>
                                <form onSubmit={handleSubmit}>
                                    <div className="grid grid-cols-2 gap-4">
                                        <div>
                                            <label className="block text-gray-700">Select Company</label>
                                            <Select
                                                options={companies.map((company) => ({
                                                    value: company._id,
                                                    label: company.name,
                                                }))}
                                                onChange={(option) => handleSelectChange(option, 'company_id')}
                                                value={findOption(
                                                    formData.company_id,
                                                    companies.map((company) => ({
                                                        value: company._id,
                                                        label: company.name,
                                                    }))
                                                )}
                                                className="basic-select"
                                                classNamePrefix="select"
                                                required
                                            />
                                        </div>
                                        <div>
                                            <label className="block text-gray-700">Select Farm</label>
                                            <Select
                                                options={farms.map((farm) => ({
                                                    value: farm._id,
                                                    label: farm.name,
                                                }))}
                                                onChange={(option) => handleSelectChange(option, 'farm_id')}
                                                value={findOption(
                                                    formData.farm_id,
                                                    farms.map((farm) => ({
                                                        value: farm._id,
                                                        label: farm.name,
                                                    }))
                                                )}
                                                isDisabled={!formData.company_id}
                                                className="basic-select"
                                                classNamePrefix="select"
                                                required
                                            />
                                        </div>
                                        <div>
                                            <label className="block text-gray-700">Select Pond</label>
                                            <Select
                                                options={ponds.map((pond) => ({
                                                    value: pond._id,
                                                    label: pond.name,
                                                }))}
                                                onChange={(option) => handleSelectChange(option, 'pond_id')}
                                                value={findOption(
                                                    formData.pond_id,
                                                    ponds.map((pond) => ({
                                                        value: pond._id,
                                                        label: pond.name,
                                                    }))
                                                )}
                                                isDisabled={!formData.farm_id}
                                                className="basic-select"
                                                classNamePrefix="select"
                                                required
                                            />
                                        </div>
                                        <div>
                                            <label className="block text-gray-700">Select Device</label>
                                            <Select
                                                options={devices.map((device) => ({
                                                    value: device._id,
                                                    label: device.name,
                                                }))}
                                                onChange={(option) => handleSelectChange(option, 'device_id')}
                                                value={findOption(
                                                    formData.device_id,
                                                    devices.map((device) => ({
                                                        value: device._id,
                                                        label: device.name,
                                                    }))
                                                )}
                                                isDisabled={!formData.pond_id}
                                                className="basic-select"
                                                classNamePrefix="select"
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="mt-4 mb-4">
                                        <label className="block text-gray-700">Sensor</label>
                                        <Select
                                            options={[{value: '', label: 'Select Sensor'}, ...sensors]}
                                            onChange={(option) => handleSelectChange(option, 'sensor_id')}
                                            value={findOption(
                                                formData.sensor_id,
                                                sensors
                                            )}
                                            className="basic-select"
                                            classNamePrefix="select"
                                            menuPortalTarget={document.body}
                                            menuPosition="fixed"
                                            isDisabled={!formData.pond_id}
                                            styles={{menuPortal: base => ({...base, zIndex: 9999})}}
                                        />
                                    </div>
                                    <div className="grid grid-cols-2 gap-4">
                                        <div>
                                            <label className="block text-gray-700">TID</label>
                                            <input
                                                type="text"
                                                name="tid"
                                                value={formData.tid}
                                                onChange={handleChange}
                                                className="w-full p-3 border border-gray-300 rounded mt-1"
                                                required
                                            />
                                        </div>
                                        <div>
                                            <label className="block text-gray-700">Slave Address</label>
                                            <input
                                                type="text"
                                                name="slave_address"
                                                value={formData.slave_address}
                                                onChange={handleChange}
                                                className="w-full p-3 border border-gray-300 rounded mt-1"
                                                required
                                            />
                                        </div>
                                        <div>
                                            <label className="block text-gray-700">Min Register Address</label>
                                            <input
                                                type="text"
                                                name="min_reg_address"
                                                value={formData.min_reg_address}
                                                onChange={handleChange}
                                                className="w-full p-3 border border-gray-300 rounded mt-1"
                                                required
                                            />
                                        </div>
                                        <div>
                                            <label className="block text-gray-700">Max Register Address</label>
                                            <input
                                                type="text"
                                                name="max_reg_address"
                                                value={formData.max_reg_address}
                                                onChange={handleChange}
                                                className="w-full p-3 border border-gray-300 rounded mt-1"
                                                required
                                            />
                                        </div>
                                    </div>


                                    <div className="col-span-2 flex justify-end mt-4">
                                        <button
                                            type="button"
                                            onClick={closeModal}
                                            className="bg-gray-500 text-white p-3 rounded mr-4"
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="submit"
                                            className="bg-blue-500 text-white p-3 rounded"
                                        >
                                            {loading ? 'Saving...' : editing ? 'Update Threshold' : 'Add Threshold'}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default PeripheralThresholdManagement;
