import React, {useEffect, useState} from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { messaging } from './firebase';  // Import Firebase messaging
import { onMessage } from 'firebase/messaging';  // Import necessary functions from Firebase
import Swal from 'sweetalert2';  // Import SweetAlert2
import Dashboard from './Dashboard';
import Login from "./Login";
import CompanyManagement from "./Pages/super_admin/CompanyManagement";
import RoleProtectedRoute from "./services/RoleProtectedRoute";
import NotAuthorized from "./services/NotAuthorized";
import {UserProvider} from "./services/UserContext";
import UserManagement from "./Pages/company_admin/UserManagement";
import FarmManagement from "./Pages/company_admin/FarmManagement";
import PondManagement from "./Pages/farmer/PondManagement";
import SensorPondAssignment from "./Pages/super_admin/SensorPondAssignment";
import PondSensorDataPage from "./Pages/farmer/PondSensorDataPage";
import PondSensorDataCards from "./Pages/farmer/PondSensorDataCards";
import SensorThresholdManagement from "./Pages/farmer/SensorThresholdManagement";
import PondDetailsPage from "./Pages/farmer/PondDetailsPage";
import FishManagement from "./Pages/super_admin/FishManagement";
import CultureCycleManagement from "./Pages/farmer/CultureCycleManagement";
import PeripheralManagement from "./Pages/super_admin/PeripheralManagement";
import ProtectedRoute from "./services/ProtectedRoute";
import NotFound from "./Pages/NotFound";
import DevicePondAssignment from "./Pages/super_admin/DevicePondAssignment";
import ConductorManagement from "./Pages/super_admin/ConductorManagement";
import PeripheralThresholdManagement from "./Pages/super_admin/PeripheralThresholdManagement";
import CompaniesAndFarms from "./Pages/super_admin/CompaniesAndFarms";
import CompaniesAndPonds from "./Pages/super_admin/CompaniesAndPonds";
import AdminFarmPond from "./Pages/company_admin/AdminFarmPond";
import PondSensorAlertPage from "./Pages/farmer/PondSensorAlertPage";
import DeviceIntervalManagement from "./Pages/super_admin/DeviceIntervalManagement";
import PondSensorDataGraphPage from "./Pages/farmer/PondSensorDataGraphPage";
import NgoAdminFarmPond from "./Pages/company_admin/NgoAdminFarmPond";
import WaterQualityGraphAdmin from "./Pages/company_admin/WaterQualityGraphAdmin";
import WaterQualityGraphNgoAdmin from "./Pages/company_admin/WaterQualityGraphNgoAdmin";

function App() {
    const roles = localStorage.getItem('userRoles');
    const [notificationReceivedAt, setNotificationReceivedAt] = useState(null);
    useEffect(() => {
        if ('serviceWorker' in navigator && 'PushManager' in window) {
            Notification.requestPermission().then((permission) => {
                if (permission === 'granted' && roles && roles.includes('farmer')) {
                    onMessage(messaging, (payload) => {
                        console.log('Message received in foreground: ', payload);
                        const notificationTitle = payload.data.title;
                        const notificationBody = payload.data.body;

                        // Display notification using SweetAlert2
                        Swal.fire({
                            title: notificationTitle,
                            text: notificationBody,
                            icon: 'info',
                            showConfirmButton: true,
                            timer: 5000 // Auto close after 5 seconds
                        });
                        setNotificationReceivedAt(new Date());
                    });
                } else {
                    console.log('Notification permission denied or not a farmer role.');
                }
            });
        } else {
            console.warn('Service Worker is not supported in this environment.');
        }
    }, [roles]);

    return (
        <UserProvider>
            <Router>
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="/" element={
                        <ProtectedRoute>
                            <Dashboard notificationReceivedAt={notificationReceivedAt} />
                        </ProtectedRoute>
                    }>
                        <Route path="companies" element={
                            <RoleProtectedRoute roles={['super_admin']}>
                                <CompanyManagement />
                            </RoleProtectedRoute>
                        } />
                        <Route path="farms" element={
                            <RoleProtectedRoute roles={['super_admin']}>
                                <CompaniesAndFarms />
                            </RoleProtectedRoute>
                        } />
                        <Route path="ponds" element={
                            <RoleProtectedRoute roles={['super_admin']}>
                                <CompaniesAndPonds />
                            </RoleProtectedRoute>
                        } />
                        <Route path="sensor-manage" element={
                            <RoleProtectedRoute roles={['super_admin']}>
                                <SensorPondAssignment />
                            </RoleProtectedRoute>
                        } />
                        <Route path="device-manage" element={
                            <RoleProtectedRoute roles={['super_admin']}>
                                <DevicePondAssignment />
                            </RoleProtectedRoute>
                        } />
                        <Route path="conductor-manage" element={
                            <RoleProtectedRoute roles={['super_admin']}>
                                <ConductorManagement />
                            </RoleProtectedRoute>
                        } />
                        <Route path="device-interval" element={
                            <RoleProtectedRoute roles={['super_admin']}>
                                <DeviceIntervalManagement />
                            </RoleProtectedRoute>
                        } />
                        <Route path="peripheral-manage" element={
                            <RoleProtectedRoute roles={['super_admin']}>
                                <PeripheralManagement />
                            </RoleProtectedRoute>
                        } />
                        <Route path="peripheral-threshold-manage" element={
                            <RoleProtectedRoute roles={['super_admin']}>
                                <PeripheralThresholdManagement />
                            </RoleProtectedRoute>
                        } />
                        <Route path="fish-manage" element={
                            <RoleProtectedRoute roles={['super_admin']}>
                                <FishManagement />
                            </RoleProtectedRoute>
                        } />
                        <Route path="users" element={
                            <RoleProtectedRoute roles={['admin']}>
                                <UserManagement />
                            </RoleProtectedRoute>
                        } />
                        <Route path="farm" element={
                            <RoleProtectedRoute roles={['admin']}>
                                <FarmManagement />
                            </RoleProtectedRoute>
                        } />
                        <Route path="farm-pond-status" element={
                            <RoleProtectedRoute roles={['admin']}>
                                <AdminFarmPond />
                            </RoleProtectedRoute>
                        } />
                        <Route path="water-quality-graph" element={
                            <RoleProtectedRoute roles={['admin']}>
                                <WaterQualityGraphAdmin />
                            </RoleProtectedRoute>
                        } />


                        <Route path="ngo-farm-pond-status" element={
                            <RoleProtectedRoute roles={['ngo_admin']}>
                                <NgoAdminFarmPond />
                            </RoleProtectedRoute>
                        } />
                        <Route path="water-quality-graph-ngo" element={
                            <RoleProtectedRoute roles={['ngo_admin']}>
                                <WaterQualityGraphNgoAdmin />
                            </RoleProtectedRoute>
                        } />

                        <Route path="pond" element={
                            <RoleProtectedRoute roles={['farmer']}>
                                <PondManagement />
                            </RoleProtectedRoute>
                        } />
                        <Route path="pond-sensor-data" element={
                            <RoleProtectedRoute roles={['farmer']}>
                                <PondSensorDataPage />
                            </RoleProtectedRoute>
                        } />
                        <Route path="pond-sensor-data-graph" element={
                            <RoleProtectedRoute roles={['farmer']}>
                                <PondSensorDataGraphPage />
                            </RoleProtectedRoute>
                        } />

                        <Route path="pond-status" element={
                            <RoleProtectedRoute roles={['farmer']}>
                                <PondSensorDataCards />
                            </RoleProtectedRoute>
                        } />
                        <Route path="sensor-thresholds" element={
                            <RoleProtectedRoute roles={['farmer']}>
                                <SensorThresholdManagement />
                            </RoleProtectedRoute>
                        } />
                        <Route path="pond-status/:pondId/details" element={
                            <RoleProtectedRoute roles={['farmer']}>
                                <PondDetailsPage  />
                            </RoleProtectedRoute>
                        } />
                        <Route path="sensor-alert" element={
                            <RoleProtectedRoute roles={['farmer']}>
                                <PondSensorAlertPage  />
                            </RoleProtectedRoute>
                        } />
                        <Route path="culture-cycle" element={
                            <RoleProtectedRoute roles={['farmer']}>
                                <CultureCycleManagement  />
                            </RoleProtectedRoute>
                        } />
                    </Route>
                    <Route path="/not-authorized" element={<NotAuthorized />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </Router>
        </UserProvider>
    );
}

export default App;
