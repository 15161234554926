import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import Select from 'react-select';
import PondSensorTable from "./PondSensorTable";

const SensorPondAssignment = () => {
    const [companies, setCompanies] = useState([]);
    const [farms, setFarms] = useState([]);
    const [ponds, setPonds] = useState([]);
    const [sensors, setSensors] = useState([]);
    const [reloadTable, setReloadTable] = useState(false);

    const [selectedCompany, setSelectedCompany] = useState('');
    const [selectedFarm, setSelectedFarm] = useState('');
    const [selectedPond, setSelectedPond] = useState('');
    const [selectedSensors, setSelectedSensors] = useState([]); // Array for multi-select

    useEffect(() => {
        const token = localStorage.getItem('token');
        axios.get(`${process.env.REACT_APP_BASE_URL}/api/companies/`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            setCompanies(response.data);
        }).catch((error) => {
            Swal.fire({
                icon: 'error',
                title: 'Failed to fetch companies',
                text: error.response?.data?.message || 'An error occurred while fetching companies.',
            });
        });
    }, []);

    useEffect(() => {
        if (selectedCompany) {
            const token = localStorage.getItem('token');
            axios.get(`${process.env.REACT_APP_BASE_URL}/api/farm/company/${selectedCompany}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then((response) => {
                setFarms(response.data);
                setPonds([]);
                setSensors([]);
                setSelectedSensors([]);
                setSelectedFarm('');
                setSelectedPond('');
            }).catch((error) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Failed to fetch farms',
                    text: error.response?.data?.message || 'An error occurred while fetching farms.',
                });
            });
        }
        else{
            setFarms([]);
            setPonds([]);
            setSensors([]);
            setSelectedSensors([]);
            setSelectedFarm('');
            setSelectedPond('');
        }
    }, [selectedCompany]);

    useEffect(() => {
        if (selectedFarm) {
            const token = localStorage.getItem('token');
            axios.get(`${process.env.REACT_APP_BASE_URL}/api/pond/farm/${selectedFarm}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then((response) => {
                setPonds(response.data);
                setSensors([]);
                setSelectedSensors([]);
            }).catch((error) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Failed to fetch ponds',
                    text: error.response?.data?.message || 'An error occurred while fetching ponds.',
                });
            });
        }
        else{
            setPonds([]);
            setSensors([]);
            setSelectedSensors([]);
            setSelectedPond('');
        }
    }, [selectedFarm]);

    useEffect(() => {
        if (selectedPond) {
            const token = localStorage.getItem('token');
            axios.get(`${process.env.REACT_APP_BASE_URL}/api/sensors/unassigned`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then((response) => {
                setSensors(response.data.map(sensor => ({
                    value: sensor._id,
                    label: `${sensor.name} - ${sensor.sensor_parameter_id?.sensor_name || ''}`
                })));
            }).catch((error) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Failed to fetch sensors',
                    text: error.response?.data?.message || 'An error occurred while fetching sensors.',
                });
            });
        }
        else{
            setSensors([]);
            setSelectedSensors([]);
        }
    }, [selectedPond]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('token');

        if (!selectedPond || selectedSensors.length === 0) {
            Swal.fire({
                icon: 'warning',
                title: 'Validation Error',
                text: 'Please select both a pond and at least one sensor.',
            });
            return;
        }

        try {
            await axios.post(`${process.env.REACT_APP_BASE_URL}/api/sensors/add-to-pond`, {
                pond_id: selectedPond,
                sensor_ids: selectedSensors.map(sensor => sensor.value) // Send array of sensor IDs
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            // Refresh the sensors list
            axios.get(`${process.env.REACT_APP_BASE_URL}/api/sensors/unassigned`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then((response) => {
                setSensors(response.data.map(sensor => ({
                    value: sensor._id,
                    label: `${sensor.name} - ${sensor.sensor_parameter_id?.sensor_name || ''}`
                })));
                setSelectedSensors([]);
            }).catch((error) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Failed to fetch sensors',
                    text: error.response?.data?.message || 'An error occurred while fetching sensors.',
                });
            });

            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Sensor(s) assigned to pond successfully.',
            });

            setReloadTable(prev => !prev);

        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Failed to assign sensor(s) to pond',
                text: error.response?.data?.message || 'An error occurred while assigning sensor(s) to pond.',
            });
        }
    };

    return (
        <div className="container mx-auto p-6">
            <h1 className="text-3xl font-bold mb-6">Assign Sensor to Pond</h1>
            <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                    <label className="block text-gray-700">Select Company</label>
                    <select
                        value={selectedCompany}
                        onChange={(e) => setSelectedCompany(e.target.value)}
                        className="w-full p-3 border border-gray-300 rounded mt-1"
                    >
                        <option value="">Select a company</option>
                        {companies.map((company) => (
                            <option key={company._id} value={company._id}>
                                {company.name}
                            </option>
                        ))}
                    </select>
                </div>
                <div>
                    <label className="block text-gray-700">Select Farm</label>
                    <select
                        value={selectedFarm}
                        onChange={(e) => setSelectedFarm(e.target.value)}
                        className="w-full p-3 border border-gray-300 rounded mt-1"
                        disabled={!selectedCompany}
                    >
                        <option value="">Select a farm</option>
                        {farms.map((farm) => (
                            <option key={farm._id} value={farm._id}>
                                {farm.name}
                            </option>
                        ))}
                    </select>
                </div>
                <div>
                    <label className="block text-gray-700">Select Pond</label>
                    <select
                        value={selectedPond}
                        onChange={(e) => setSelectedPond(e.target.value)}
                        className="w-full p-3 border border-gray-300 rounded mt-1"
                        disabled={!selectedFarm}
                    >
                        <option value="">Select a pond</option>
                        {ponds.map((pond) => (
                            <option key={pond._id} value={pond._id}>
                                {pond.name}
                            </option>
                        ))}
                    </select>
                </div>
                <div>
                    <label className="block text-gray-700">Select Sensors</label>
                    <Select
                        isMulti
                        value={selectedSensors}
                        onChange={setSelectedSensors}
                        options={sensors}
                        className="basic-multi-select mt-1"
                        classNamePrefix="select"
                        isDisabled={!selectedPond}
                    />
                </div>
                <button
                    type="submit"
                    className="bg-blue-500 text-white p-3 rounded hover:bg-blue-600 transition duration-200"
                >
                    Assign Sensor(s)
                </button>
            </form>
            <PondSensorTable reload={reloadTable} />
        </div>
    );
};

export default SensorPondAssignment;
