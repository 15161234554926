import React, {useState, useContext, useEffect} from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import {UserContext} from "./services/UserContext";
import {getToken} from "firebase/messaging";
import {messaging} from "./firebase";

function Login() {
    const [formData, setFormData] = useState({
        email: '',
        password: '',
        fcm_token:''
    });

    const { updateUserRoles } = useContext(UserContext);
    const navigate = useNavigate();

    useEffect(() => {
        if ('serviceWorker' in navigator && 'PushManager' in window) {
            Notification.requestPermission().then((permission) => {
                if (permission === 'granted') {
                    console.log('Notification permission granted.');
                    getToken(messaging, { vapidKey: 'BCGFmSORCK_guznkc5zBEt0-i4mXikqC-WYRxDY-gNdrCPTtrIEsYTrn44Se7UaGHBFWhr2OwrzjYGND7Iq96ZY' })
                        .then((currentToken) => {
                            if (currentToken) {
                                console.log(currentToken);
                                setFormData((prevFormData) => ({
                                    ...prevFormData,
                                    fcm_token: currentToken,
                                }));
                            } else {
                                console.log('No registration token available.');
                            }
                        })
                        .catch((err) => {
                            console.error('Error getting token', err);
                        });
                } else {
                    console.log('Notification permission denied.');
                }
            });
        } else {
            console.warn('Service Worker is not supported in this environment.');
        }
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/auth/login`, formData);
            const token = response.data.token;

            // Store the token in localStorage
            localStorage.setItem('token', token);

            // Fetch roles and update context
            const roleResponse = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/auth/validate-token`, {
                headers: { Authorization: `Bearer ${token}` },
            });

            if (roleResponse.data.valid) {
                updateUserRoles(roleResponse.data.data.roles);
            }

            // Redirect to the dashboard
            navigate('/');
        } catch (error) {
            console.error('Login failed:', error);
            toast.error('Login failed. Please check your credentials and try again.', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };

    return (
        <div className="min-h-screen bg-gray-100 flex items-center justify-center bg-cover" style={{ backgroundImage: "url('/login-bg.jpeg')" }}>
            <div className="bg-white bg-white bg-opacity-70 backdrop-blur-lg shadow-lg rounded-lg p-8 max-w-md w-full">
                <h2 className="text-4xl font-bold text-center mb-6 text-blue-600">Sign In</h2>
                <form onSubmit={handleSubmit} className="space-y-6">
                    <div>
                        <label className="block text-gray-700 text-sm font-semibold">Email Address</label>
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            className="w-full p-3 border border-gray-300 rounded mt-1 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            placeholder="you@example.com"
                            required
                        />
                    </div>
                    <div>
                        <label className="block text-gray-700 text-sm font-semibold">Password</label>
                        <input
                            type="password"
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                            className="w-full p-3 border border-gray-300 rounded mt-1 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            placeholder="••••••••"
                            required
                        />
                    </div>
                    <button
                        type="submit"
                        className="w-full bg-blue-500 text-white p-3 rounded-lg font-semibold hover:bg-blue-600 transition duration-200"
                    >
                        Sign In
                    </button>
                </form>
            </div>
            <ToastContainer />
        </div>
    );
}

export default Login;
