import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const GaugeComponent = ({ value, minValue, maxValue, threshold }) => {
    const percentage = ((value - minValue) / (maxValue - minValue)) * 100;
    const isInRange = value >= threshold.min && value <= threshold.max;

    return (
        <div className="gauge-container" style={{ width: '150px', margin: 'auto' }}>
            <CircularProgressbar
                value={percentage}
                text={`${value}`}
                styles={buildStyles({
                    pathColor: isInRange ? '#00FF00' : '#ef3131',
                    textColor: '#333',
                    trailColor: '#d6d6d6',
                    backgroundColor: '#f8f9fa',
                    strokeLinecap: 'round', // Makes the ends of the path rounded
                    textSize: '16px',
                })}
            />
            {/*<div className="gauge-info mt-3 text-center">*/}
            {/*    <p className="text-xs text-gray-500">*/}
            {/*        Range: {threshold.min} - {threshold.max}*/}
            {/*    </p>*/}
            {/*    <p className="text-xs text-gray-500">*/}
            {/*        Min: {minValue} | Max: {maxValue}*/}
            {/*    </p>*/}
            {/*</div>*/}
        </div>
    );
};

export default GaugeComponent;
