import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import '@sweetalert2/theme-dark/dark.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faWater, faThermometerHalf, faBolt, faCogs, faVial, faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, CartesianGrid } from 'recharts';

function AdminFarmPond() {
    const [farms, setFarms] = useState([]);
    const [selectedFarm, setSelectedFarm] = useState('');
    const [pondStatus, setPondStatus] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [chartData, setChartData] = useState(null);

    useEffect(() => {
        fetchFarms();
    }, []);

    const fetchFarms = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/farm/all/list`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setFarms(response.data.farms);
        } catch (error) {
            Swal.fire('Error', 'Failed to fetch farms.', 'error');
        }
    };

    const fetchPondStatus = async (farmId) => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/pond/pond-status/${farmId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setPondStatus(response.data);
        } catch (error) {
            Swal.fire('Error', 'Failed to fetch pond status.', 'error');
        }
    };

    const fetchSensorSummary = async (pondId) => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/pond/sensor-24-summery/${pondId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setChartData(response.data.data);
            setIsModalOpen(true);
        } catch (error) {
            Swal.fire('Error', 'Failed to fetch sensor summary.', 'error');
        }
    };


    const handleFarmSelect = (e) => {
        const farmId = e.target.value;
        setSelectedFarm(farmId);
        if (farmId) {
            fetchPondStatus(farmId);
        } else {
            setPondStatus([]);
        }
    };

    const handlePondClick = (pondId) => {
        fetchSensorSummary(pondId);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setChartData(null);
    };


    const getSensorIcon = (sensorName) => {
        switch (sensorName) {
            case 'DO':
                return faWater;
            case 'Water Temp':
                return faThermometerHalf;
            case 'Ammonia':
            case 'Water PH':
                return faVial;
            default:
                return faVial;
        }
    };

    const generateIndividualSensorCharts = () => {
        if (!chartData) return null;

        return chartData.sensors.map((sensor) => {
            const data = sensor.data.map((entry) => ({
                time: entry.datetime,
                value: parseFloat(entry.averageValue),
            }));

            return (
                <div key={sensor.sensorType} className="mb-8">
                    <h3 className="text-lg font-semibold mb-4">
                        {sensor.sensorType} ({sensor.data[0]?.unit || ''})
                    </h3>
                    <LineChart
                        width={500}
                        height={300}
                        data={data}
                        margin={{top: 20, right: 30, left: 40, bottom: 30}}
                    >
                        <CartesianGrid strokeDasharray="3 3"/>
                        <XAxis
                            dataKey="time"
                            label={{value: 'Time', position: 'bottom', offset: 10}}
                        />
                        <YAxis
                            label={{value: 'Value', angle: -90, position: 'insideLeft', offset: 10}}
                        />
                        <Tooltip/>
                        <Legend wrapperStyle={{bottom: -10}}/>
                        <Line type="monotone" dataKey="value" stroke="rgba(75, 192, 192, 0.6)" strokeWidth={4}/>
                    </LineChart>
                </div>
            );
        });
    };

    return (
        <div className="container mx-auto p-6">

            <h1 className="text-3xl font-bold text-blue-700 mb-6">Pond Status</h1>

            <div className="mb-8">
                <label htmlFor="farmSelect" className="block text-gray-700 mb-2">
                    Select a Farm:
                </label>
                <select
                    id="farmSelect"
                    value={selectedFarm}
                    onChange={handleFarmSelect}
                    className="w-full p-3 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
                >
                    <option value="">-- Select a Farm --</option>
                    {farms.map((farm) => (
                        <option key={farm._id} value={farm._id}>
                            {farm.name}
                        </option>
                    ))}
                </select>
            </div>

            {/* Display Pond Status */}
            {pondStatus.length > 0 && (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6">
                    {pondStatus.map((pond) => (
                        <div key={pond.pond_id}
                            className="bg-white shadow-lg rounded-lg overflow-hidden transition-transform transform hover:scale-105 duration-300 flex flex-col h-full"

                        >
                            {/* Card Header */}
                            <div className="bg-cyan-600 text-white p-3 flex flex-col items-center">
                                <h2 className="text-2xl font-bold mb-2">{pond.pond_name}</h2>
                                {/* Button with Icon */}
                                <button
                                    onClick={() => handlePondClick(pond.pond_id)}
                                    className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center mt-2 mb-2"
                                >
                                    <FontAwesomeIcon icon={faInfoCircle} className="mr-2"/> {/* Adjust icon */}
                                    Water quality summary
                                </button>
                            </div>


                            {/* Card Body */}
                            <div className="p-6 flex-1">
                                <div className="flex flex-wrap gap-6 justify-between">
                                    {/* Sensor Data Section */}
                                    <div className="flex-1 bg-gray-50 p-4 rounded-lg shadow-md">
                                        <h3 className="text-lg font-semibold text-gray-800 mb-4">Sensors ( {pond.last_updated} ago )</h3>
                                        <div className="space-y-4">
                                            {pond.sensors.map((sensor, index) => (
                                                <div
                                                    key={index}
                                                    className="flex items-center justify-between p-3 bg-white rounded-lg shadow-sm"
                                                >
                                                    <div className="flex items-center">
                                                        <FontAwesomeIcon
                                                            icon={getSensorIcon(sensor.sensor_name)}
                                                            className="text-blue-500 mr-3"
                                                        />
                                                        <h4 className="text-md font-semibold text-gray-800">{sensor.sensor_name}</h4>
                                                    </div>
                                                    <p className="text-gray-700">
                                                        <span className="font-bold">{sensor.value}</span> {sensor.unit}
                                                    </p>
                                                </div>
                                            ))}
                                        </div>
                                    </div>

                                    {/* Conductor Data Section */}
                                    <div className="flex-1 bg-gray-50 p-4 rounded-lg shadow-md">
                                        <h3 className="text-lg font-semibold text-gray-800 mb-4">Conductors</h3>
                                        <div className="space-y-4">
                                            {pond.conductors.length === 0 ? (
                                                <p className="text-gray-500">No conductors available</p>
                                            ) : (
                                                pond.conductors.map((conductor, index) => (
                                                    <div
                                                        key={index}
                                                        className="flex flex-col p-3 bg-white rounded-lg shadow-sm"
                                                    >
                                                        <div className="flex items-center justify-between">
                                                            <div className="flex items-center">
                                                                <FontAwesomeIcon icon={faBolt}
                                                                                 className="text-yellow-500 mr-3"/>
                                                                <h4 className="text-md font-semibold text-gray-800">
                                                                    Conductor : {index + 1}
                                                                </h4>
                                                            </div>
                                                            <p
                                                                className={`font-bold ${
                                                                    conductor.status === 'on' ? 'text-green-500' : 'text-red-500'
                                                                }`}
                                                            >
                                                                {conductor.status.toUpperCase()}
                                                            </p>
                                                        </div>

                                                        {/* Peripheral Data Section */}
                                                        <div className="mt-4">
                                                            <h5 className="text-md font-semibold text-gray-700 mb-2">Peripherals</h5>
                                                            {conductor.peripherals.length === 0 ? (
                                                                <p className="text-gray-500">No peripherals
                                                                    available</p>
                                                            ) : (
                                                                conductor.peripherals.map((peripheral, pIndex) => (
                                                                    <div
                                                                        key={pIndex}
                                                                        className="flex items-center justify-between p-3 bg-gray-100 rounded-lg shadow-sm mt-2"
                                                                    >
                                                                        <div className="flex items-center">
                                                                            <FontAwesomeIcon icon={faCogs}
                                                                                             className="text-green-500 mr-3"/>
                                                                            <h4 className="text-sm font-medium text-gray-800">{peripheral.name}</h4>
                                                                        </div>
                                                                        <p
                                                                            className={`font-bold ${
                                                                                peripheral.status === 'on' ? 'text-green-500' : 'text-red-500'
                                                                            }`}
                                                                        >
                                                                            {peripheral.status.toUpperCase()}
                                                                        </p>
                                                                    </div>
                                                                ))
                                                            )}
                                                        </div>
                                                    </div>
                                                ))
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}

            {isModalOpen && (
                <div className="fixed z-50 inset-0 bg-black bg-opacity-50 overflow-auto text-center">
                    <div className="flex items-center justify-center min-h-screen px-4 py-8 text-center">
                        <div
                            className="bg-white rounded-lg shadow-2xl w-full max-w-6xl mx-auto p-6 transform transition-all text-center">
                            {/* Modal Header */}
                            <div className="flex justify-between items-center border-b pb-4 mb-4 ">
                                <h2 className="text-2xl font-bold text-gray-800 text-center">
                                    {chartData?.pond??"No"} - Sensor Summary
                                </h2>
                                <button onClick={closeModal} className="text-gray-500 hover:text-gray-800">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none"
                                         viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                              d="M6 18L18 6M6 6l12 12"/>
                                    </svg>
                                </button>
                            </div>

                            {/* Modal Body with Two Columns */}
                            <div className="overflow-y-auto max-h-96">
                                {chartData ? (
                                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6 w-full">
                                        {generateIndividualSensorCharts().map((chart, index) => (
                                            <div key={index} className="bg-white p-4 rounded-lg shadow-md">
                                                {chart}
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <p className="text-center text-gray-500 w-full">Loading...</p>
                                )}
                            </div>


                            {/* Modal Footer */}
                            <div className="mt-6 text-right">
                                <button
                                    onClick={closeModal}
                                    className="bg-red-500 text-white px-6 py-2 rounded-lg hover:bg-red-600 focus:outline-none transition duration-200"
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            )}
        </div>
    );
}

export default AdminFarmPond;
